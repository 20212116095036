import React from 'react';
import { EarlySettlementDetails } from 'common';
import { DescriptionListAccordion } from '@vwfs-bronson/bronson-react';
import { InfoIcon } from '@cp-shared-10/frontend-ui';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';

type IconLabelProps = {
    text: string;
    tooltip: string;
};

const IconLabel: React.FC<IconLabelProps> = ({ text, tooltip }) => {
    return (
        <>
            {text}
            <InfoIcon text={tooltip} />
        </>
    );
};

const getField = (
    val: string | number | undefined,
    label: string,
    visible: boolean,
    tooltip?: string,
): React.ReactNode => {
    return (
        <DescriptionListAccordion.DL label={tooltip ? <IconLabel text={label} tooltip={tooltip} /> : label}>
            {visible ? val : '-'}
        </DescriptionListAccordion.DL>
    );
};

export type EarlySettlementDetailsViewProps = {
    details?: EarlySettlementDetails;
};

export const EarlySettlementDetailsView: React.FC<EarlySettlementDetailsViewProps> = ({ details }) => {
    const { t, f } = useTranslationWithFormatting('total-early-settlement');

    if (!details) return null;

    const {
        instalmentsToSettle,
        outstandingCapital,
        unpaidOverpaidBalanceUpToDate,
        administrationFee,
        accuredInterestAmountToBePaid,
        nextInsurancePaymentDue,
        outstandingInsurancePremium,
        interestOnTheLatePayments,
        totalSettlementAmount,
        validityPeriodOfTheQuotation,
    } = details;

    return (
        <DescriptionListAccordion
            totalLabel={t('details.field.total-settlement-amount')}
            totalValue={f(totalSettlementAmount, TranslationFormat.CURRENCY)}
            totalLabelNote={
                <>
                    <span>{t('details.field.validity-period-of-the-quotation')}</span>
                    <span> {f(validityPeriodOfTheQuotation, TranslationFormat.DATE)}</span>
                </>
            }
        >
            <DescriptionListAccordion.Item defaultOpen nonCollapsable title={t('details.headline')}>
                <>
                    {getField(
                        instalmentsToSettle,
                        t('details.field.instalments-to-settle'),
                        !!instalmentsToSettle,
                        t('details.tooltip.instalments-to-settle'),
                    )}
                    {getField(
                        f(outstandingCapital, TranslationFormat.CURRENCY),
                        t('details.field.outstanding-capital'),
                        !!outstandingCapital,
                        t('details.tooltip.outstanding-capital'),
                    )}
                    {getField(
                        f(unpaidOverpaidBalanceUpToDate, TranslationFormat.CURRENCY),
                        t('details.field.unpaid-overpaid-balance'),
                        !!unpaidOverpaidBalanceUpToDate,
                        t('details.tooltip.unpaid-overpaid-balance'),
                    )}
                    {getField(
                        f(administrationFee, TranslationFormat.CURRENCY),
                        t('details.field.administration-fee'),
                        !!administrationFee,
                        t('details.tooltip.administration-fee'),
                    )}
                    {getField(
                        f(accuredInterestAmountToBePaid, TranslationFormat.CURRENCY),
                        t('details.field.accured-interest-amount'),
                        !!accuredInterestAmountToBePaid,
                        t('details.tooltip.accured-interest-amount'),
                    )}
                    {getField(
                        f(nextInsurancePaymentDue, TranslationFormat.CURRENCY),
                        t('details.field.next-insurance-payment'),
                        !!nextInsurancePaymentDue,
                        t('details.tooltip.next-insurance-payment'),
                    )}
                    {getField(
                        f(outstandingInsurancePremium, TranslationFormat.CURRENCY),
                        t('details.field.outstanding-insurance-premium'),
                        !!outstandingInsurancePremium,
                        t('details.tooltip.outstanding-insurance-premium'),
                    )}
                    {getField(
                        f(interestOnTheLatePayments, TranslationFormat.CURRENCY),
                        t('details.field.interest-on-the-late-payments'),
                        !!interestOnTheLatePayments,
                        t('details.tooltip.interest-on-the-late-payments'),
                    )}
                </>
            </DescriptionListAccordion.Item>
        </DescriptionListAccordion>
    );
};
