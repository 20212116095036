import { getMyProfileEndpoint, MyProfileData } from 'common';
import { getSimpleDataFetchSlice } from '@cp-shared-10/frontend-integration';
import { CpDataApi } from 'cp-xhr';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-10/common-utilities';

const { reducer, fetchData, updateData } = getSimpleDataFetchSlice<MyProfileData, DefaultBusinessMarketApiErrorCode>({
    dataName: 'myProfile',
    fetchCallback() {
        return CpDataApi.get<MyProfileData>(getMyProfileEndpoint()).then((response) => response.data);
    },
});

export default reducer;
export const fetchMyProfile = fetchData;
export const updateMyProfile = updateData;
