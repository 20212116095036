import React from 'react';
import { Layout } from '@vwfs-bronson/bronson-react';
import { ValidatedDate, ValidatedInput } from '@cp-shared-10/frontend-ui';
import { useTranslation } from 'react-i18next';
import { CustomerType } from 'common';
import { FileUpload } from '../../../../file-upload';
import { IdCardTypeSelection } from '../../../id-card-type-selection/IdCardTypeSelection';

export const PostalAddressFormFields: React.FC<{ customerType?: CustomerType }> = ({ customerType }) => {
    const { t, i18n } = useTranslation('my-profile-addresses-details');
    const translationPrefix = 'edit-view.postal';
    const language = i18n.languages[0];

    if (!customerType) return null;

    const privateCustomerFields: JSX.Element = (
        <>
            <Layout.Item>
                <IdCardTypeSelection
                    label={t(`${translationPrefix}.document.label`)}
                    name={'personIdCardData.type'}
                    testId={'personIdCardData.type'}
                />
            </Layout.Item>
            <Layout.Item default="1/3" s="1/1">
                <ValidatedInput
                    label={t(`${translationPrefix}.id-number.label`)}
                    name="personIdCardData.number"
                    testId="personIdCardData.number"
                    type="text"
                    isMandatory
                />
            </Layout.Item>
            <Layout.Item default="1/3" s="1/1">
                <ValidatedDate
                    language={language}
                    name={'personIdCardData.issueDate'}
                    testId={'personIdCardData.issueDate'}
                    outputDateFormat={'d.m.Y'}
                    label={t(`${translationPrefix}.date-of-issue.label`)}
                    isMandatory
                />
            </Layout.Item>
            <Layout.Item default="1/3" s="1/1">
                <ValidatedInput
                    label={t(`${translationPrefix}.city-of-issue.label`)}
                    tooltip={t(`${translationPrefix}.city-of-issue.tooltip`)}
                    name="personIdCardData.issueCity"
                    testId="personIdCardData.issueCity"
                    type="text"
                    isMandatory
                />
            </Layout.Item>
            <Layout.Item>
                <FileUpload name="frontOfDocument" label={t(`${translationPrefix}.document.file-upload.front`)} />
            </Layout.Item>
            <Layout.Item>
                <FileUpload
                    name="backOfDocument"
                    label={t(`${translationPrefix}.document.file-upload.back.label`)}
                    tooltip={t(`${translationPrefix}.document.file-upload.back.tooltip`)}
                    isMandatory={false}
                />
            </Layout.Item>
        </>
    );

    const companyCustomerFields: JSX.Element = (
        <Layout.Item>
            <FileUpload name="registryDocument" label={t(`${translationPrefix}.document.file-upload.registry`)} />
        </Layout.Item>
    );
    return customerType === 'Private' ? privateCustomerFields : companyCustomerFields;
};
