import React from 'react';
import { ContractParties } from 'common';
import { DefinitionList, DefinitionListItem } from '@cp-shared-10/frontend-ui';

export const ContractPartiesUi: React.FC<{ contractParties?: ContractParties }> = ({ contractParties }) => {
    if (!contractParties) {
        return null;
    }

    const itemList: DefinitionListItem[] = Object.entries(contractParties)
        .map(([person, roleAndName]) => {
            return {
                label: roleAndName?.role || '',
                value: roleAndName?.name || '',
                testId: person,
            };
        })
        .filter((element) => !!element.label && !!element.value);

    return itemList && <DefinitionList split={true} list={itemList} />;
};
