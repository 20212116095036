import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidatedSelectItem, ValidatedSelect } from '@cp-shared-10/frontend-ui';
import { getCities } from 'common';

type CitySelectionProps = {
    name: string;
    testId: string;
    currentCitySelection: string;
    maxItem?: number;
    isMandatory?: boolean;
    label?: string;
};

type InputProps = {
    defaultOpen: boolean;
    inputProps: { autoFocus: boolean };
};

export const CitySelection: React.FC<CitySelectionProps> = ({
    name,
    testId,
    currentCitySelection,
    isMandatory,
    label,
}) => {
    const { t } = useTranslation('my-profile-addresses-details');
    const [inputProps, setInputProps] = useState<InputProps>({ defaultOpen: false, inputProps: { autoFocus: false } });
    const translationPrefix = 'edit-view.common';

    const onSelect = (): void => {
        setInputProps({ defaultOpen: false, inputProps: { autoFocus: false } });
    };

    const options: ValidatedSelectItem[] = getCities().map((city, index) => ({
        testId: `city-selection-item-${index}`,
        value: city,
        key: city,
        label: city,
        onOptionSelect: onSelect,
    }));

    return (
        <>
            <ValidatedSelect
                isMandatory={isMandatory}
                key={currentCitySelection}
                name={name}
                label={label || t(`${translationPrefix}.city.label`)}
                tooltip={t(`${translationPrefix}.city.tooltip`)}
                {...inputProps}
                testId={testId}
                selectItems={options}
                placeholder={currentCitySelection}
            />
        </>
    );
};
