import {
    BusinessRegistrationData,
    getBusinessRegistrationEndpoint,
    RegistrationError,
    RegistrationResult,
} from 'common';
import { WithDefaultCpIntegrationErrors } from '@cp-shared-10/common-utilities';
import { CpDataApi } from 'cp-xhr';
import { parseErrorResponse } from '@cp-shared-10/frontend-integration';
import { BusinessFormProps } from '../types';

export const handleSubmit = (
    formValues: BusinessFormProps,
    successCallback: (registrationResult: RegistrationResult, registrationData: BusinessFormProps) => void,
    errorCallback: (errorCode: WithDefaultCpIntegrationErrors<RegistrationError>, errorMessage: string) => void,
): void => {
    const { businessCustomerNumber, companyName, companyIdentificationNumber } = formValues;
    const registrationData: BusinessRegistrationData = {
        customerNumber: +businessCustomerNumber,
        companyName,
        companyIdentificationNumber,
    };
    CpDataApi.post(getBusinessRegistrationEndpoint(), registrationData)
        .then((response) => {
            const responseData: RegistrationResult = response.data;
            successCallback(responseData, formValues);
        })
        .catch((error) => {
            const errorCode = parseErrorResponse<RegistrationError>(error).code;
            const errorMessage = error.response?.data?.message;
            errorCallback(errorCode, errorMessage);
        });
};
