import * as Yup from 'yup';
import { IdCardError } from '../types';
import { defaultIdCardError } from '../messages';
import { IdCard, IdCardType } from '../../apis';
import { formatCpDate } from '@cp-shared-10/common-utilities';
import { DATE_FORMAT } from '../../formatters';

export const idCardValidator = (errors: IdCardError = defaultIdCardError): Yup.SchemaOf<IdCard> =>
    Yup.object().shape({
        type: Yup.mixed<IdCardType>()
            .oneOf(Object.values(IdCardType), errors.type.oneOf)
            .required(errors.type.required),
        number: Yup.string()
            .trim()
            .when('type', {
                is: IdCardType.PASSPORT,
                then: Yup.string().required(errors.number.requiredForPassport),
                otherwise: Yup.string().required(errors.number.required),
            })
            .when('type', (type: IdCardType, schema: Yup.StringSchema): Yup.StringSchema => {
                if (type === IdCardType.NATIONAL_ID) return schema.matches(/^[A-Z]{2}\d{6}$/, errors.number.matches);
                if (type === IdCardType.PASSPORT) return schema.matches(/.*/, '');
                if (type === IdCardType.RESIDENCE_CARD) return schema.matches(/^[A-Z]{2}\d{7}$/, errors.number.matches);
                return schema.max(0, errors.number.unknownType);
            })
            .required(errors.number.required),
        issueDate: Yup.string()
            .trim()
            .required(errors.issueDate.required)
            .test(
                'valid',
                errors.issueDate.valid,
                (value?: string): boolean =>
                    formatCpDate(value).isValid() || formatCpDate(value, DATE_FORMAT).isValid(),
            ),
        issueCity: Yup.string().trim().required(errors.issueCity.required),
    });
