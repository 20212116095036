import React from 'react';
import { Greeting as GreetingShared } from '@cp-shared-10/frontend-ui';

import { Greeting } from 'common';

export const GreetingUi: React.FC<{ greeting?: Greeting }> = ({ greeting }) => {
    if (!greeting || !greeting.message) return null;

    return <GreetingShared fullGreetingText={greeting.message} />;
};
