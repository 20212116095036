import React from 'react';
import { Button, ButtonContainer } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { DataOverview } from '@cp-shared-10/frontend-ui';

type DeleteConfirmationProps = {
    onConfirm: () => void;
    onCancel: () => void;
};

export const DeleteConfirmation: React.FC<DeleteConfirmationProps> = ({ onConfirm, onCancel }) => {
    const { t } = useTranslation('my-profile-bank-details');

    return (
        <>
            <DataOverview title={t('edit-view.delete-confirmation')} />
            <ButtonContainer center className={'u-mt-large'}>
                <Button secondary onClick={onCancel} testId="cancel-button" type="button">
                    {t('translation:editable-section-nav.cancel')}
                </Button>
                <Button onClick={onConfirm} testId="confirm-button" type="submit">
                    {t('translation:editable-section-nav.confirm')}
                </Button>
            </ButtonContainer>
        </>
    );
};
