import { createGetContractBasedDataFetchSlice } from '@cp-shared-10/frontend-integration';
import { CpDataApi } from 'cp-xhr';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-10/common-utilities';
import { AmortizationDetails } from 'common';

const { reducer, fetchData } = createGetContractBasedDataFetchSlice<
    AmortizationDetails[],
    DefaultBusinessMarketApiErrorCode
>({
    dataName: 'amortizationDetails',
    fetchCallback(link: string) {
        return CpDataApi.get(link).then((response) => response.data);
    },
});

export default reducer;
export const fetchAmortizationDetails = fetchData;
