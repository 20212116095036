import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import {
    Accordion,
    AccordionItem,
    DefinitionListHorizontal,
    DefinitionListItem,
    Skeleton,
} from '@cp-shared-10/frontend-ui';
import { compact } from 'lodash';
import { Layout } from '@vwfs-bronson/bronson-react';
import { VehicleServiceResponse } from '@cp-shared-10/apis';
import { getFuelTypeTranslation } from '../utils';

export const CollapsibleVehicleDetailsPlaceholder: React.FC = () => (
    <Layout>
        <Layout.Item>
            <Skeleton height={40} />
        </Layout.Item>
        <Layout.Item>
            <Skeleton height={40} />
        </Layout.Item>
    </Layout>
);

export const CollapsibleVehicleDetails: React.FC<{
    details?: VehicleServiceResponse;
    isLoading?: boolean;
}> = ({ details, isLoading }) => {
    const { t } = useTranslation('vehicle-details-and-image');
    const [accordionOpen, setAccordionOpen] = useState(false);

    const getVehicleDetails = useCallback((t: TFunction, details?: VehicleServiceResponse): DefinitionListItem[] => {
        const { engineSize, enginePower, fuelType = '' } = details || {};
        const { value: engineSizeValue, unit: engineSizeUnit } = engineSize || {};
        const { value: enginePowerValue, unit: enginePowerUnit } = enginePower || {};
        return compact([
            engineSizeValue &&
                engineSizeUnit && {
                    label: t('engine-size'),
                    value: `${engineSizeValue} ${engineSizeUnit} ${getFuelTypeTranslation(fuelType, t)}`,
                    testId: 'engine-size',
                },
            enginePowerValue &&
                enginePowerUnit && {
                    label: t('engine-power'),
                    value: `${enginePowerValue} ${enginePowerUnit}`,
                    testId: 'engine-power',
                },
        ]);
    }, []);

    const itemList: DefinitionListItem[] = useMemo(() => getVehicleDetails(t, details), [
        getVehicleDetails,
        t,
        details,
    ]);

    const detailsComponent = useMemo(() => {
        return isLoading ? <CollapsibleVehicleDetailsPlaceholder /> : <DefinitionListHorizontal list={itemList} />;
    }, [isLoading, itemList]);

    const handleOnChange = (refs: string[]): void => (refs.length ? setAccordionOpen(true) : setAccordionOpen(false));

    return (
        <>
            {!!itemList.length && (
                <Accordion className={'u-hide@s-up'} lazyRender={true} onChange={handleOnChange}>
                    <AccordionItem
                        title={t(accordionOpen ? 'details-button.expand' : 'details-button.collapse')}
                        defaultOpen={accordionOpen}
                    >
                        {detailsComponent}
                    </AccordionItem>
                </Accordion>
            )}
            <div className={'u-hide@s'}>{detailsComponent}</div>
        </>
    );
};
