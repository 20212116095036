import moment from 'moment-timezone';
import numeral from 'numeral';

import 'moment/locale/sk';
import 'numeral/locales/sk';
import { CPDate, formatCpDate } from '@cp-shared-10/common-utilities';

// Check out https://momentjs.com/timezone/
export const SK_TIMEZONE = 'Europe/Bratislava';

moment.tz.setDefault(SK_TIMEZONE);

export const DATE_FORMAT = 'DD.MM.YYYY';

// TODO Check the format definitions
const locale = 'sk';
export const numberFormat = '0,0';
export const numberFormatRoundedOne = '0,0.0';
export const numberFormatRoundedTwo = '0,0.00';
const percentageNumberFormat = '0,0.00';
const numeralFormat = '0o';
const currencyFormat = '0,0.00';
const longDateFormat = 'DD.MM.YYYY';

/**
 * 'sk' uses wrong thousands delimiter
 */
numeral.locales['sk'].delimiters.thousands = '.';

export function changeLocale(locale: 'en' | 'sk'): void {
    moment.locale(locale);
    numeral.locale(locale);
}

changeLocale(locale);

export function formatAsCurrency(value?: number): string {
    return typeof value === 'number' ? `${numeral(value).format(currencyFormat)} €` : '';
}

export function formatAsDate(date?: CPDate): string {
    return date ? formatCpDate(date).format(longDateFormat) : '';
}

export function formatAsNumber(value?: number, format: string = numberFormat): string {
    return typeof value === 'number' ? numeral(value).format(format) : '';
}

export function formatAsNumeral(value?: number): string {
    return typeof value === 'number' ? numeral(value).format(numeralFormat) : '';
}

export function formatAsDistance(value?: number): string {
    return typeof value === 'number' ? numeral(value).format(numberFormat) : '';
}

export function formatAsEmission(value?: number): string {
    return typeof value === 'number' ? `${numeral(value).format(numberFormat)} g/km` : '';
}

export function formatAsPercentageRate(value?: number): string {
    return typeof value === 'number' ? `${numeral(value).format(percentageNumberFormat)}%` : '';
}

export function formatAsIban(iban?: string): string {
    return (
        iban
            ?.replace(/[^\dA-Z]/g, '')
            .replace(/(.{4})/g, '$1 ')
            .trim() ?? ''
    );
}

export function formatAsOrdinalNumber(value?: number | string): string {
    return value ? `${value}.` : '';
}

export function getValue(formattedValue?: string): number | undefined {
    return formattedValue ? numeral(formattedValue).value() || undefined : undefined;
}

export function formatAsFileSize(bytes: number, decimal: number): string {
    const unit = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return (!bytes && '0 Bytes') || (bytes / Math.pow(1024, i)).toFixed(decimal) + ' ' + unit[i];
}
