import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidatedSelectItem, ValidatedSelect } from '@cp-shared-10/frontend-ui';
import { TFunction } from 'i18next';

type InputProps = {
    defaultOpen: boolean;
    inputProps: { autoFocus: boolean };
};

type TitleSelectionProps = {
    name: string;
    testId: string;
    isMandatory?: boolean;
};

const getAllOptions = (t: TFunction, translationPrefix: string): string[] => {
    return ['ing', 'dip', 'jud', 'mud', 'mvd', 'phd', 'rnd', 'pae', 'dr', 'mgr'].map((option: string) =>
        t(`${translationPrefix}.title.options.${option}`),
    );
};

export const TitleSelection: React.FC<TitleSelectionProps> = ({ name, testId, isMandatory }) => {
    const translationPrefix = 'identification.edit-view.private';
    const { t } = useTranslation('my-profile');
    const [inputProps, setInputProps] = useState<InputProps>({ defaultOpen: false, inputProps: { autoFocus: false } });

    const onSelect = () => {
        setInputProps({ defaultOpen: false, inputProps: { autoFocus: false } });
    };

    const options: ValidatedSelectItem[] = ['', ...getAllOptions(t, translationPrefix)].map((title, index) => ({
        testId: `title-selection-item-${index}`,
        value: title,
        key: title,
        label: title,
        onOptionSelect: onSelect,
    }));

    return (
        <ValidatedSelect
            isMandatory={isMandatory}
            name={name}
            label={t(`${translationPrefix}.title.label`)}
            {...inputProps}
            testId={testId}
            selectItems={options}
        />
    );
};
