import {
    FinancialDetails,
    PaymentDayChange,
    getChangePaymentDayEndpoint,
    getMarketApiHealthEndpoint,
    changePaymentDateValidationSchema,
    MarketApiHealthResponse,
} from 'common';
import React, { useState, useEffect } from 'react';
import {
    Button,
    Heading,
    ButtonContainer,
    Fieldset,
    Layout,
    RadioButton,
    FormFieldRadioButtonGroup,
} from '@vwfs-bronson/bronson-react';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { dashboardPagePath } from 'components/navigation/paths';
import { useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import { CpDataApi } from 'cp-xhr';
import { EditStatus } from '../../utils';
import { Spinner, useAnalyticsActionTracker, Notification, NotificationStatus } from '@cp-shared-10/frontend-ui';
import { PaymentDayNotification } from '../payment-day-notification/PaymentDayNotification';

type EditViewProps = {
    details?: FinancialDetails;
    encryptedContractNumber: string;
    finishEditing: (editStatus: EditStatus) => void;
};

export const EditView: React.FC<EditViewProps> = ({ details, encryptedContractNumber, finishEditing }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [editStatus, setEditStatus] = useState<EditStatus>(EditStatus.NOT_PERFORMED);
    const [isApiReachable, setIsApiReachable] = useState<boolean>(true);
    const { onAction: onEditCancel } = useAnalyticsActionTracker('financialDetailsEditDueDateSectionCancel');
    const { onAction: onEditFail } = useAnalyticsActionTracker('financialDetailsEditDueDateSectionSubmitFailed');
    const { onAction: onSuccess } = useAnalyticsActionTracker('financialDetailsEditDueDateSectionSuccess');

    const history = useHistory();
    const { t } = useTranslationWithFormatting('financial-details');
    const prefix = `financing-section.monthly-due-day.edit`;

    const radioItems = [5, 10, 15, 20, 25, 30];

    const [paymentDay, setPaymentDay] = useState<number | undefined>();

    useEffect(() => {
        CpDataApi.get<MarketApiHealthResponse>(getMarketApiHealthEndpoint())
            .then((res) => {
                if (res?.data?.status === 'OK' && res?.data?.connections === 'OK') {
                    setIsApiReachable(true);
                } else {
                    setIsApiReachable(false);
                }
            })
            .catch((_) => {
                setIsApiReachable(false);
            });
    }, []);

    const createRadioButton = () => {
        return radioItems.map((value, index) => {
            return (
                <RadioButton
                    key={index}
                    name="paymentDay"
                    value={value}
                    testId={`test-paymentDay-${value}`}
                    onChange={() => {
                        setPaymentDay(value);
                    }}
                >
                    {value}
                </RadioButton>
            );
        });
    };

    useEffect(() => {
        setEditStatus(EditStatus.NOT_PERFORMED);
    }, [paymentDay]);

    const handleSubmit = () => {
        if (paymentDay) {
            setEditStatus(EditStatus.NOT_PERFORMED);
            setIsSubmitting(true);
            const preparedData: PaymentDayChange = {
                paymentDay: paymentDay,
            };

            if (preparedData.paymentDay === details?.monthlyDueDay) {
                onSuccess();
                setIsSubmitting(false);
                setEditStatus(EditStatus.NO_CHANGE);
                return;
            }

            CpDataApi.put(getChangePaymentDayEndpoint(encryptedContractNumber), preparedData)
                .then(() => {
                    setIsSubmitting(false);
                    setIsApiReachable(true);
                    finishEditing(EditStatus.SUCCESS);
                    onSuccess();
                })
                .catch(() => {
                    onEditFail();
                    setIsSubmitting(false);
                    setIsApiReachable(false);
                    setEditStatus(EditStatus.ERROR);
                });
        }
    };

    const validationMessages = {
        paymentDay: {
            required: t(`${prefix}.validation.payment-day`),
            oneOf: t(`${prefix}.validation.payment-day`),
        },
    };

    return (
        <>
            {/*If seat get onboarded use currentBrand === 'seat' ? 'alternative' : 'primary' */}
            <div data-theme={'primary'} className={'u-mb'}>
                <Heading className="c-data-overview__header u-pt u-mb-none" level={6}>
                    {t(`${prefix}.headline`)}
                    <Button
                        element="a"
                        link
                        className="c-data-overview__button u-mb-small u-ml-xsmall u-mr-small c-icon  c-icon--[semantic-close]"
                        onClick={() => history.push(dashboardPagePath())}
                        testId={'x-back-button'}
                        disabled={!!isSubmitting}
                    ></Button>
                </Heading>
                {isSubmitting ? (
                    <Spinner center />
                ) : (
                    <>
                        <Formik
                            initialValues={{
                                paymentDay: undefined,
                            }}
                            validationSchema={changePaymentDateValidationSchema(validationMessages)}
                            onSubmit={handleSubmit}
                        >
                            <Form onSubmit={(e) => e.preventDefault()}>
                                <Fieldset>
                                    {isApiReachable && (
                                        <Fieldset.Row>
                                            <Layout>
                                                <Layout.Item default="1/1" s="1/1">
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: t(`${prefix}.label`),
                                                        }}
                                                    />
                                                    <div className="u-pr">
                                                        <FormFieldRadioButtonGroup>
                                                            {createRadioButton()}
                                                        </FormFieldRadioButtonGroup>
                                                    </div>
                                                </Layout.Item>
                                            </Layout>
                                        </Fieldset.Row>
                                    )}
                                    <Fieldset.Row>
                                        {isApiReachable && <PaymentDayNotification editStatus={editStatus} />}
                                        {!isApiReachable && (
                                            <Notification
                                                className="u-mt"
                                                text={t(`${prefix}.service-not-available`)}
                                                status={NotificationStatus.error}
                                            />
                                        )}
                                        {isApiReachable && paymentDay && (
                                            <Notification
                                                text={t(`${prefix}.new-date-notification`, {
                                                    date: paymentDay,
                                                })}
                                                className="c-horizontal-slider--pale c-product-info__caption"
                                                status={NotificationStatus.info}
                                            />
                                        )}
                                        <ButtonContainer className={'u-mt'} center>
                                            <Button
                                                secondary
                                                testId="back-to-dashboard"
                                                onClick={(): void => {
                                                    onEditCancel();
                                                    history.push(dashboardPagePath());
                                                }}
                                            >
                                                {t(`${prefix}.cancel-button`)}
                                            </Button>
                                            <Button
                                                disabled={!isApiReachable || !paymentDay}
                                                onClick={handleSubmit}
                                                testId="submit-paymentDay"
                                                type="submit"
                                            >
                                                {t(`${prefix}.confirm-button`)}
                                            </Button>
                                        </ButtonContainer>
                                    </Fieldset.Row>
                                </Fieldset>
                            </Form>
                        </Formik>
                        {isApiReachable && (
                            <Notification
                                className="u-mt"
                                text={t(`${prefix}.info`)}
                                status={NotificationStatus.info}
                            />
                        )}
                    </>
                )}
            </div>
        </>
    );
};
