import { ContentSection, Heading } from '@vwfs-bronson/bronson-react';
import { PaymentOverview } from 'components/payment-overview/PaymentOverview';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export const PaymentOverviewPage: React.FC = () => {
    const { t } = useTranslation('payment-overview');
    const { encryptedContractNumber } = useParams<{ encryptedContractNumber: string }>();
    const title = t('headline');
    return (
        <ContentSection pageWrapSize="medium">
            <Heading level={1}>{title}</Heading>
            <PaymentOverview encryptedContractNumber={encryptedContractNumber} />
        </ContentSection>
    );
};
