import { HeroImage } from '@cp-shared-10/frontend-ui';

import { LogoutSuccess } from 'common';
import React from 'react';
import { useLogin } from '../../../auth/useLogin';

export type LogoutSuccessUiProps = {
    logoutType: 'auto' | 'manual';
    logoutSuccess?: LogoutSuccess;
};

export const LogoutSuccessUi: React.FC<LogoutSuccessUiProps> = ({ logoutSuccess, logoutType }) => {
    const login = useLogin();

    if (!logoutSuccess) {
        return null;
    }

    return (
        <HeroImage
            title={logoutSuccess[logoutType]?.title || ''}
            subTitle={logoutSuccess[logoutType]?.subTitle}
            buttonText={logoutSuccess[logoutType]?.buttonText}
            clickHandler={login}
        />
    );
};
