import { formatAsFileSize } from 'common';
import { FileUpload as SharedFileUpload, InfoIcon } from '@cp-shared-10/frontend-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { maxFileSize } from '../../config';
import { VALID_FILE_FORMATS } from './helpers';

export type FileUploadProps = { name: string; label?: string; tooltip?: string; isMandatory?: boolean };

export const FileUpload: React.FC<FileUploadProps> = ({ name, label, tooltip, isMandatory = true }) => {
    const { t } = useTranslation('file-upload');
    const sizeError = t('size-error');
    const typeError = t('type-error');
    const sizeAndTypeError = (
        <>
            {sizeError}
            <br />
            {typeError}
        </>
    );
    return (
        <>
            {label && (
                <div className="u-mb-xsmall">
                    <span
                        dangerouslySetInnerHTML={{
                            __html: isMandatory ? `${label} *` : label,
                        }}
                        className={'u-mb-xsmall'}
                    />
                    {tooltip && <InfoIcon text={tooltip} />}
                </div>
            )}
            <SharedFileUpload
                name={name}
                descriptionText={t('headline')}
                descriptionSupplementaryText={t('text')}
                buttonText={t('button')}
                cancelLabel={t('translation:editable-section-nav.cancel')}
                fileSizeFormatter={(bytes: number): string => formatAsFileSize(bytes, 2)}
                maxFileSize={maxFileSize}
                validFileFormats={VALID_FILE_FORMATS}
                sizeError={sizeError}
                typeError={typeError}
                sizeAndTypeError={sizeAndTypeError}
            />
        </>
    );
};
