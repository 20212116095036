import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    ButtonContainer,
    Card,
    Layout,
    LicensePlate as LicensePlateShared,
    Modal,
} from '@vwfs-bronson/bronson-react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { dashboardPagePath } from 'components/navigation/paths';
import { ContractHeader, Notification, NotificationStatus, Spinner } from '@cp-shared-10/frontend-ui';

import { useContract } from '../useContract';
import { ComparisonSection } from './comparison-section';
import {
    CalculatedMileage,
    getAcceptMileageEndpoint,
    getMileageEndpoint,
    ChangeMileageValidatorErrors,
    changeMileageValidationSchema,
    Mileage,
    MileageChangeBody,
} from 'common';
import { CpDataApi } from 'cp-xhr';
import { NewContractDetails } from './new-contract-details';
import { CurrentTerms } from './ChangeMileageTypes';
import { useContractDetails } from '../contract-details';
import { NoConnectionNotification } from 'components/notifications/no-connection/NoConnectionNotification';

export type ChangeMileageProps = {
    encryptedContractNumber: string;
};

enum MessageType {
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS',
    NONE = 'NONE',
}

export const ChangeMileage: React.FC<ChangeMileageProps> = ({ encryptedContractNumber }) => {
    const { t } = useTranslation('change-mileage');
    const history = useHistory();
    const [mileageAvailable, setMileageAvailable] = useState<Mileage>();
    const [chosenOption, setChosenOption] = useState<number>();
    const [newTermsCalculated, setNewTermsCalculated] = useState<CalculatedMileage>();
    const [currentTerms, setCurrentTerms] = useState<CurrentTerms>();
    const [messageType, setMessageType] = useState<MessageType>(MessageType.NONE);
    const [isMileageLoading, setIsMileageLoading] = useState<boolean>(true);
    const [isCurrentTermsLoading, setIsCurrentTermsLoading] = useState<boolean>(false);
    const [mileageLoadingError, setMileageLoadingError] = useState<boolean>(false);
    const { data: contract, isLoading, loadingError } = useContract(encryptedContractNumber, {
        encryptedContractNumber: true,
    });

    const {
        data: contractDetails,
        isLoading: isLoadingContractDetails,
        loadingError: loadingErrorContractDetails,
    } = useContractDetails(contract?.contractNumber || '');

    const validationErrors: ChangeMileageValidatorErrors = {
        newMileageAmount: {
            sameAsPrevious: t('validation.sameMileage'),
        },
    };

    const formik = useFormik({
        initialValues: { newMileageAmount: undefined },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onSubmit: () => {},
        validationSchema:
            currentTerms?.currentMileage &&
            changeMileageValidationSchema(currentTerms?.currentMileage, validationErrors),
    });

    useEffect(() => {
        setNewTermsCalculated(undefined);
        if (
            chosenOption &&
            chosenOption !== currentTerms?.currentMileage &&
            mileageAvailable?.settlementDate?.[0] &&
            !formik.errors?.newMileageAmount
        ) {
            setIsMileageLoading(true);
            const requestBody: MileageChangeBody = {
                newMileageAmount: chosenOption,
                currentMileageAmount: currentTerms?.currentMileage,
                settlementDate: mileageAvailable?.settlementDate?.[0],
            };
            CpDataApi.post(getMileageEndpoint(encryptedContractNumber), requestBody)
                .then((res) => {
                    setNewTermsCalculated(res.data);
                    setMileageLoadingError(false);
                })
                .catch((_) => {
                    setMileageLoadingError(true);
                })
                .finally(() => {
                    setIsMileageLoading(false);
                });
        }
    }, [chosenOption, formik.errors, encryptedContractNumber, currentTerms, mileageAvailable]);

    useEffect(() => {
        if (contractDetails) {
            setIsMileageLoading(true);
            CpDataApi.get<Mileage>(getMileageEndpoint(encryptedContractNumber))
                .then((res) => {
                    setMileageAvailable(res.data);
                    setMileageLoadingError(false);
                    setIsCurrentTermsLoading(true);
                    CpDataApi.post<CalculatedMileage>(getMileageEndpoint(encryptedContractNumber), {
                        newMileageAmount: res.data?.mileageAmountField?.[0],
                        ...(contractDetails?.financial &&
                            'contractedMileAge' in contractDetails?.financial && {
                                currentMileageAmount: contractDetails?.financial?.contractedMileAge,
                            }),
                        settlementDate: res.data?.settlementDate?.[0],
                    })
                        .then((res) => {
                            setCurrentTerms({
                                monthlyRate: res?.data?.monthlyRate,
                                lastInstallment: res?.data?.lastInstallment,
                                currentMileage: res?.data?.currentMileage,
                            });
                            setMileageLoadingError(false);
                        })
                        .catch((_) => {
                            setMileageLoadingError(true);
                        })
                        .finally(() => setIsCurrentTermsLoading(false));
                })
                .catch((_) => {
                    setMileageLoadingError(true);
                })
                .finally(() => {
                    setIsMileageLoading(false);
                });
        }
    }, [contractDetails, encryptedContractNumber]);

    const acceptMileageChange = () => {
        setIsMileageLoading(true);
        if (chosenOption && mileageAvailable?.settlementDate?.[0]) {
            const requestBody: MileageChangeBody = {
                newMileageAmount: chosenOption,
                currentMileageAmount: currentTerms?.currentMileage,
                settlementDate: mileageAvailable?.settlementDate?.[0],
            };
            CpDataApi.post(getAcceptMileageEndpoint(encryptedContractNumber), requestBody)
                .then(() => {
                    setMessageType(MessageType.SUCCESS);
                })
                .catch((_) => {
                    setMessageType(MessageType.ERROR);
                })
                .finally(() => {
                    setIsMileageLoading(false);
                });
        }
    };

    const handleModalClose = (): void => setMessageType(MessageType.NONE);

    const handleModalConfirm = (): void => {
        handleModalClose();
        history.push(dashboardPagePath());
    };

    const LicensePlate = contract?.licensePlate ? (
        <LicensePlateShared
            countryCode={t('country-code')}
            size={'small'}
            registrationNumber={contract?.licensePlate}
            horizontalStripEu={true}
            euStars={true}
        />
    ) : null;

    return (
        <>
            <Layout>
                {(isLoading || isMileageLoading || isCurrentTermsLoading || isLoadingContractDetails) &&
                    !(loadingError || loadingErrorContractDetails) && <Spinner fullPage />}
                {contract && (
                    <>
                        <Layout.Item default="1/1">
                            <Card element="article">
                                <ContractHeader
                                    carInformation={contract.brandModelType!}
                                    contractCategory={contract.productName}
                                    contractNumber={contract.contractNumber!}
                                    contractIdentifier={LicensePlate}
                                />
                            </Card>
                        </Layout.Item>
                        <Layout.Item className="u-mt" default="1/1">
                            <ComparisonSection
                                currentTerms={currentTerms}
                                mileageAvailable={mileageAvailable}
                                setChosenOption={setChosenOption}
                                chosenOption={chosenOption}
                                calculatedTerms={newTermsCalculated}
                                setFieldValue={formik.setFieldValue}
                                validationError={formik.errors?.newMileageAmount}
                            />
                        </Layout.Item>

                        {mileageLoadingError && (
                            <Layout.Item className="u-mt" default="1/1" s="1/1">
                                <NoConnectionNotification testId={'no-connection'} />
                            </Layout.Item>
                        )}

                        <Layout.Item className="u-mt" default="1/1">
                            <NewContractDetails calculatedTerms={newTermsCalculated} />
                        </Layout.Item>
                    </>
                )}
                <Layout.Item className="u-mt" default="1/1" s="1/1">
                    {(loadingError || loadingErrorContractDetails) && (
                        <div className="u-mb">
                            <NoConnectionNotification testId={'no-connection'} />
                        </div>
                    )}
                    {newTermsCalculated && (
                        <Notification
                            status={NotificationStatus.warning}
                            headline={t('warning.headline')}
                            text={t('warning.text')}
                            className={'u-mb'}
                        />
                    )}
                    <Modal
                        shown={messageType === MessageType.ERROR}
                        status="error"
                        title={t('modal-error.headline')}
                        onClose={handleModalClose}
                        onCancel={handleModalConfirm}
                        onClickOutside={handleModalClose}
                        buttonCancelText={t('modal-error.button')}
                        testId={'error-modal'}
                    >
                        {t('modal-error.text')}
                    </Modal>
                    <Modal
                        shown={messageType === MessageType.SUCCESS}
                        status="success"
                        title={t('modal-success.headline')}
                        onClose={handleModalClose}
                        onConfirm={handleModalConfirm}
                        onClickOutside={handleModalClose}
                        buttonConfirmText={t('modal-success.button')}
                        testId={'success-modal'}
                    >
                        {t('modal-success.text')}
                    </Modal>
                    <ButtonContainer center>
                        <Button secondary onClick={(): void => history.push(dashboardPagePath())} testId="cancelButton">
                            {t('cancel-button')}
                        </Button>
                        <Button
                            testId="submitButton"
                            type="button"
                            onClick={acceptMileageChange}
                            disabled={isEmpty(newTermsCalculated)}
                        >
                            {t('submit-button')}
                        </Button>
                    </ButtonContainer>
                </Layout.Item>
            </Layout>
        </>
    );
};
