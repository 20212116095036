export const flattenObject = (obj: object): object => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    //@ts-ignore
    const flat = (obj: object, masterKey?: string) =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        //@ts-ignore
        Object.entries(obj).flatMap(([key, value]) => {
            const k = `${masterKey ? `${masterKey}.` : ''}${key}`;
            if (typeof value === 'object' && value !== null) return flat(value, k);
            return [[k, value]];
        });
    return Object.fromEntries(flat(obj));
};
