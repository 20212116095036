import React from 'react';
import { isEmpty } from 'lodash';
import { CarInsurance as CarInsuranceType, BundledProductType } from 'common';
import { DefinitionList, DefinitionListItem } from '@cp-shared-10/frontend-ui';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';

export const CarInsurance: React.FC<{ carInsurance: CarInsuranceType; isOperativeLeasing: boolean }> = ({
    carInsurance,
    isOperativeLeasing,
}) => {
    const { t, f } = useTranslationWithFormatting('bundled-products');

    if (!carInsurance || isEmpty(carInsurance)) {
        return null;
    }

    const {
        productType,
        productName,
        insurer,
        policyNumber,
        status,
        startDate,
        endDate,
        endLabel,
        monthlyAmount,
        participationRateOfKaskoInsuranceV,
        participationRateOfKaskoInsuranceN,
    } = carInsurance;

    const itemList: DefinitionListItem[] = [
        {
            label: t('car-insurance.insurer'),
            value: insurer || '-',
            testId: 'car-insurance-insurer',
        },
        {
            label: t('car-insurance.policy-number'),
            value: policyNumber || '-',
            testId: 'car-insurance-policy-number',
        },
        {
            label: t('car-insurance.status'),
            value: status || '-',
            testId: 'car-insurance-status',
        },
        {
            label: t('car-insurance.start-date'),
            value: startDate ? f(startDate, TranslationFormat.DATE) : '-',
            testId: 'car-insurance-start-date',
        },
        {
            label: t('car-insurance.end-date'),
            value: endDate ? f(endDate, TranslationFormat.DATE) : endLabel || '-',
            testId: 'car-insurance-end-date',
        },
        {
            label: t('car-insurance.monthly-amount'),
            value: !isOperativeLeasing ? monthlyAmount || '-' : undefined,
            testId: 'car-insurance-monthly-amount',
        },
        {
            label: t('car-insurance.participation-rate-of-kasko-insurance-v'),
            value:
                productType === BundledProductType.ACCIDENT_INSURANCE
                    ? participationRateOfKaskoInsuranceV || '-'
                    : undefined,
            testId: 'car-insurance-participation-rate-of-kasko-insurance-v',
        },
        {
            label: t('car-insurance.participation-rate-of-kasko-insurance-n'),
            value:
                productType === BundledProductType.ACCIDENT_INSURANCE
                    ? participationRateOfKaskoInsuranceN || '-'
                    : undefined,
            testId: 'car-insurance-participation-rate-of-kasko-insurance-n',
        },
    ].filter(({ value }) => value !== undefined);

    return (
        <div className="u-mt">
            <h5>{productName}</h5>
            <DefinitionList split={true} list={itemList} />
        </div>
    );
};
