import { ContractInfo, getContractsEndpoint } from 'common';
import { getSimpleDataFetchSlice } from '@cp-shared-10/frontend-integration';
import { CpDataApi } from 'cp-xhr';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-10/common-utilities';

const { reducer, fetchData } = getSimpleDataFetchSlice<ContractInfo[], DefaultBusinessMarketApiErrorCode>({
    dataName: 'request',
    fetchCallback() {
        return CpDataApi.get(getContractsEndpoint()).then((response) => response.data);
    },
});

export default reducer;
export const fetchRequest = fetchData;
