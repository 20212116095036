import React, { useEffect, useState } from 'react';
import { Layout } from '@vwfs-bronson/bronson-react';
import { StepperUi } from './stepper';
import { StepperType } from './stepper/StepperType';
import { useTranslation } from 'react-i18next';
import { PartialEarlySettlementViewType } from './PartialEarlySettlementViewType';
import { useContract } from 'components/contracts/useContract';
import { Calculation } from './calculation/Calculation';
import { Spinner, Notification, NotificationStatus } from '@cp-shared-10/frontend-ui';
import { QuoteDetails } from './quote-details';
import { AcceptPartialEarlySettlementBody, PartialEarlySettlementDetails } from 'common';
import { OfflinePayment } from './offline-payment';

export type PartialEarlySettlementProps = {
    encryptedContractNumber: string;
};

export const PartialEarlySettlement: React.FC<PartialEarlySettlementProps> = ({ encryptedContractNumber }) => {
    const { t } = useTranslation('partial-early-settlement');
    const [currentView, setCurrentView] = useState<PartialEarlySettlementViewType>(
        PartialEarlySettlementViewType.CALCULATIONS,
    );
    const [selectedOption, setSelectedOption] = useState<PartialEarlySettlementDetails>();
    const [acceptRequest, setAcceptRequest] = useState<AcceptPartialEarlySettlementBody>();

    const { data: contract, loadingError: loadingErrorContract } = useContract(encryptedContractNumber, {
        encryptedContractNumber: true,
    });

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 200);
    }, [currentView]);

    const stepperValue: StepperType[] = [
        {
            title: t('stepper.step-1'),
            viewType: PartialEarlySettlementViewType.CALCULATIONS,
        },
        { title: t('stepper.step-2'), viewType: PartialEarlySettlementViewType.QUOTE_DETAILS },
        { title: t('stepper.step-3'), viewType: PartialEarlySettlementViewType.OFFLINE_PAYMENT },
    ];

    return (
        <>
            <Layout>
                {loadingErrorContract ? (
                    <Notification
                        className="u-text-left u-mt-large"
                        status={NotificationStatus.error}
                        text={t('calculate.errors.be-not-available')}
                    />
                ) : (
                    <>
                        <Layout.Item default="1/1" s="1/1">
                            <StepperUi stepper={stepperValue} currentViewType={currentView} />
                        </Layout.Item>
                        {!contract && <Spinner fullPage />}
                        {contract && currentView === PartialEarlySettlementViewType.CALCULATIONS && (
                            <Calculation
                                contract={contract}
                                encryptedContractNumber={encryptedContractNumber}
                                setSelectedOption={setSelectedOption}
                                setAcceptRequest={setAcceptRequest}
                                continueToQuoteDetails={(): void => {
                                    setCurrentView(PartialEarlySettlementViewType.QUOTE_DETAILS);
                                }}
                            />
                        )}
                        {contract && currentView === PartialEarlySettlementViewType.QUOTE_DETAILS && (
                            <QuoteDetails
                                contractDetails={contract}
                                encryptedContractNumber={encryptedContractNumber}
                                partialEarlySettlementDetails={selectedOption}
                                acceptRequest={acceptRequest}
                                back={(): void => setCurrentView(PartialEarlySettlementViewType.CALCULATIONS)}
                                continueToOfflinePayment={(): void =>
                                    setCurrentView(PartialEarlySettlementViewType.OFFLINE_PAYMENT)
                                }
                            />
                        )}
                        {contract && currentView === PartialEarlySettlementViewType.OFFLINE_PAYMENT && (
                            <OfflinePayment
                                contractDetails={contract}
                                encryptedContractNumber={encryptedContractNumber}
                                totalSettlementAmount={selectedOption?.totalSettlementAmount}
                            />
                        )}
                    </>
                )}
            </Layout>
        </>
    );
};
