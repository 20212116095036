import { CpDataApi } from 'cp-xhr';
import React from 'react';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-10/frontend-storybook-extensions';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-10/common-utilities';
import { ContractsWithAllValues as Contracts } from 'components/contracts/ExampleData';
import {
    EarlySettlementActiveData,
    EarlySettlementAllowedData,
    EarlySettlementCalculatingData,
    EarlySettlementExpiredData,
    EarlySettlementForbiddenData,
} from '../ExampleData';
import { ContractsMock } from '../../ContractsResponseMocks';
import { getEarlySettlementEndpoint, getGeneratePaymentEndpoint } from 'common';
import { EarlySettlementPaymentResultWithAllData } from '../../../payment-page/ExampleData';

const mockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Active: {
        status: 200,
        responseBody: EarlySettlementActiveData,
    },
    Allowed: {
        status: 200,
        responseBody: EarlySettlementAllowedData,
    },
    Forbidden: {
        status: 200,
        responseBody: EarlySettlementForbiddenData,
    },
    Calculating: {
        status: 200,
        responseBody: EarlySettlementCalculatingData,
    },
    Expired: {
        status: 200,
        responseBody: EarlySettlementExpiredData,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const EarlySettlementMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Early Settlement Response',
    method: 'get',
    defaultMockOption: 'Active',
    mockOptions,
    url: Contracts[0]._links?.earlySettlement || '',
};

const requestMockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: EarlySettlementActiveData,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const RequestEarlySettlementMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Request Early Settlement',
    method: 'post',
    defaultMockOption: 'Success',
    mockOptions: requestMockOptions,
    url: getEarlySettlementEndpoint('ASXDFSHEI121'),
};

const generatePaymentMockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: EarlySettlementPaymentResultWithAllData,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const GeneratePaymentEndpointMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Generate Payment',
    method: 'post',
    defaultMockOption: 'Success',
    mockOptions: generatePaymentMockOptions,
    url: getGeneratePaymentEndpoint('ASXDFSHEI121'),
};

export const EarlySettlementResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [
        ContractsMock,
        EarlySettlementMock,
        RequestEarlySettlementMock,
        GeneratePaymentEndpointMock,
    ]);

    return <div>{storyFn()}</div>;
};
