import { AmortizationDetails, ContractType } from 'common';
import { TableHeaderEntry } from '@cp-shared-10/frontend-ui';
import { TFunction } from 'i18next';
import { TranslationFormat } from '../../../localization/useTranslationWithFormatting';

export type ToColumnValues = (string | { value: string; hiddenForMobile: boolean })[];

const generateHeadings = (tableType: string, t: TFunction): TableHeaderEntry[] => {
    const vat = tableType === 'financial' ? [{ heading: t('table.financial.vat'), hiddenForMobile: true }] : [];
    return [
        t(`table.${tableType}.number-of-payment`),
        t(`table.${tableType}.due-date`),
        { heading: t(`table.${tableType}.capital`), hiddenForMobile: true },
        { heading: t(`table.${tableType}.pending-capital`), hiddenForMobile: true },
        { heading: t(`table.${tableType}.interest-amount`), hiddenForMobile: true },
        { heading: t(`table.${tableType}.total-installment`), hiddenForMobile: true },
        ...vat,
        { heading: t(`table.${tableType}.car-insurance`), hiddenForMobile: true },
        { heading: t(`table.${tableType}.cpi`), hiddenForMobile: true },
        { heading: t(`table.${tableType}.service-maintenance-tires`), hiddenForMobile: true },
        t(`table.${tableType}.total`),
    ];
};

export const getColumnHeadings = (contractType: ContractType, t: TFunction): TableHeaderEntry[] => {
    switch (contractType) {
        case ContractType.FINANCIAL_LEASING:
            return generateHeadings('financial', t);
        case ContractType.OPERATIVE_LEASING:
            return [
                t('table.operative.number-of-payment'),
                t('table.operative.due-date'),
                { heading: t('table.operative.tax-due-date'), hiddenForMobile: true },
                { heading: t('table.operative.tax-due-date-to'), hiddenForMobile: true },
                { heading: t('table.operative.basis-for-vat'), hiddenForMobile: true },
                { heading: t('table.operative.vat'), hiddenForMobile: true },
                t('table.operative.total'),
            ];
        default:
            return generateHeadings('credit', t);
    }
};

export const generateRow = (row: AmortizationDetails, contractType: ContractType, f: Function): ToColumnValues => {
    const getDefaultCells = (isFinancialLeasing?: boolean): string[] => {
        const vat = isFinancialLeasing ? [f(row.vat, TranslationFormat.CURRENCY)] : [];
        return [
            f(row.capital, TranslationFormat.CURRENCY),
            f(row.pendingCapital, TranslationFormat.CURRENCY),
            f(row.interestAmount, TranslationFormat.CURRENCY),
            f(row.totalInstallment, TranslationFormat.CURRENCY),
            ...vat,
            f(row.carInsurance, TranslationFormat.CURRENCY),
            f(row.cpi, TranslationFormat.CURRENCY),
            f(row.serviceMaintenanceTires, TranslationFormat.CURRENCY),
        ];
    };

    const getCells = (): string[] => {
        switch (contractType) {
            case ContractType.FINANCIAL_LEASING:
                return getDefaultCells(true);
            case ContractType.OPERATIVE_LEASING:
                return [
                    f(row.taxDueDateFrom, TranslationFormat.DATE),
                    f(row.taxDueDateTo, TranslationFormat.DATE),
                    f(row.basicForVat, TranslationFormat.CURRENCY),
                    f(row.vat, TranslationFormat.CURRENCY),
                ];
            default:
                return getDefaultCells();
        }
    };
    const rowCellsHiddenForMobile: ToColumnValues = getCells().map((value) => ({
        value: !!value ? value.split(' ').join('') : '-',
        hiddenForMobile: true,
    }));

    return [
        f(row.numberOfPayment, TranslationFormat.NUMERAL) || '-',
        f(row.dueDate, TranslationFormat.DATE) || '-',
        ...rowCellsHiddenForMobile,
        f(row.total, TranslationFormat.CURRENCY).split(' ').join('') || '-',
    ];
};
