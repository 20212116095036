import React, { useEffect, useState } from 'react';
import { Layout, ContentSection, Combobox } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { formatCpDate } from '@cp-shared-10/common-utilities';
import moment, { Moment } from 'moment';
import { TFunction } from 'i18next';
import { TimeFrameFilterItem } from '@cp-shared-10/frontend-ui';
import { uniqBy } from 'lodash';
import { ChangeRequestLogEntryWithId } from 'common';

interface ChangeLogFiltersProps {
    setFilteredEntries: (filteredEntries: ChangeRequestLogEntryWithId[]) => void;
    changeRequestLogEntries: ChangeRequestLogEntryWithId[];
    resetPage: () => void;
    page: number;
}

type FilterType = { key: number | string; value: number | string }[];
type Timeframe = 'LAST_3_MONTHS' | 'LAST_6_MONTHS' | 'LAST_12_MONTHS' | 'LAST_2_YEARS' | 'LAST_4_YEARS';

export const ChangeLogFilters: React.FC<ChangeLogFiltersProps> = ({
    setFilteredEntries,
    changeRequestLogEntries,
    resetPage,
    page,
}) => {
    const { t } = useTranslation('change-log-request');
    const allOptionKey = t('all');

    const defaultFilter = { value: [{ key: allOptionKey, value: allOptionKey }] };

    const [currentContractNumberFilterKey, setContractNumberFilterKey] = useState<string | number>(allOptionKey);
    const [currentTypeOfRequestFilterKey, setTypeOfRequestFilterKey] = useState<string | number>(allOptionKey);
    const [currentDateFilterKey, setDateFilterKey] = useState<string | number>(allOptionKey);

    const timeframeMomentMapping: Record<Timeframe, Moment> = {
        LAST_3_MONTHS: formatCpDate().subtract(3, 'months').toMoment(),
        LAST_6_MONTHS: formatCpDate().subtract(6, 'months').toMoment(),
        LAST_12_MONTHS: formatCpDate().subtract(12, 'months').toMoment(),
        LAST_2_YEARS: formatCpDate().subtract(2, 'years').toMoment(),
        LAST_4_YEARS: formatCpDate().subtract(4, 'years').toMoment(),
    };

    const getTimeFrameFilterItems = (t: TFunction): TimeFrameFilterItem[] => {
        const translationPrefix = 'filter-data';
        const currentDate = formatCpDate().toMoment();
        return [
            {
                from: timeframeMomentMapping['LAST_3_MONTHS'],
                to: currentDate,
                value: t(`${translationPrefix}.last-3-months`),
                key: '3m',
            },
            {
                from: timeframeMomentMapping['LAST_6_MONTHS'],
                to: currentDate,
                value: t(`${translationPrefix}.last-6-months`),
                key: '6m',
            },
            {
                from: timeframeMomentMapping['LAST_12_MONTHS'],
                to: currentDate,
                value: t(`${translationPrefix}.last-12-months`),
                key: '12m',
            },
            {
                from: timeframeMomentMapping['LAST_2_YEARS'],
                to: currentDate,
                value: t(`${translationPrefix}.last-2-years`),
                key: '2y',
            },
            {
                from: timeframeMomentMapping['LAST_4_YEARS'],
                to: currentDate,
                value: t(`${translationPrefix}.last-4-years`),
                key: '4y',
            },
        ];
    };

    useEffect(() => {
        setFilteredEntries(
            changeRequestLogEntries
                .filter((document) => {
                    if (currentContractNumberFilterKey === allOptionKey) {
                        return true;
                    }
                    return currentContractNumberFilterKey === document.contractNumber.toString();
                })
                .filter((document) => {
                    if (currentTypeOfRequestFilterKey === allOptionKey) {
                        return true;
                    }
                    return currentTypeOfRequestFilterKey === document.typeOfRequest;
                })
                .filter((document) => {
                    if (currentDateFilterKey === allOptionKey) {
                        return true;
                    }

                    const currentTimeFrameFilterItems = getTimeFrameFilterItems(t).filter(
                        (filterItem) => filterItem.key.toString() === currentDateFilterKey.toString(),
                    )[0];

                    const currentSelectedTimeFrom = currentTimeFrameFilterItems.from;
                    const currentSelectedTimeTo = currentTimeFrameFilterItems.to;

                    if (
                        currentSelectedTimeFrom <= moment(document.dateOfReceipt, 'YYYY-MM-DD') &&
                        currentSelectedTimeTo >= moment(document.dateOfReceipt, 'YYYY-MM-DD')
                    ) {
                        return true;
                    }

                    return false;
                }),
        );
        // eslint-disable-next-line
    }, [currentContractNumberFilterKey, currentTypeOfRequestFilterKey, currentDateFilterKey, page]);

    const onContractNumberChange = (value: FilterType): void => {
        if (value[0].key !== currentContractNumberFilterKey) {
            setContractNumberFilterKey(value[0].key);
            resetPage();
        }
    };

    const onTypeOfRequestChange = (value: FilterType): void => {
        if (value[0].key !== currentTypeOfRequestFilterKey) {
            setTypeOfRequestFilterKey(value[0].key);
            resetPage();
        }
    };

    const onDateChange = (value: FilterType): void => {
        if (value[0].key !== currentDateFilterKey) {
            setDateFilterKey(value[0].key);
            resetPage();
        }
    };

    return (
        <ContentSection altBackground={true} className={'u-p'}>
            <Layout className={'u-text-middle u-mb-small'}>
                <Layout.Item default={`1/3`}>
                    <p>{t('filters.contract-number')}</p>
                    <Combobox onChange={(e: FilterType) => onContractNumberChange(e)} defaultState={defaultFilter}>
                        <Combobox.Item optionKey={allOptionKey} optionValue={allOptionKey}>
                            {allOptionKey}
                        </Combobox.Item>
                        {uniqBy(changeRequestLogEntries, 'contractNumber').map((contract) => {
                            const contractNumber: string = contract.contractNumber.toString();
                            return (
                                <Combobox.Item
                                    key={contractNumber}
                                    optionKey={contractNumber}
                                    optionValue={contractNumber ? contractNumber : t('missing-contract-id')}
                                >
                                    {contractNumber ? contractNumber : t('missing-contract-id')}
                                </Combobox.Item>
                            );
                        })}
                    </Combobox>
                </Layout.Item>
                <Layout.Item default={`1/3`}>
                    <p>{t('filters.type-of-request')}</p>
                    <Combobox onChange={(e: FilterType) => onTypeOfRequestChange(e)} defaultState={defaultFilter}>
                        <Combobox.Item optionKey={allOptionKey} optionValue={allOptionKey}>
                            {allOptionKey}
                        </Combobox.Item>
                        {uniqBy(changeRequestLogEntries, 'typeOfRequest').map((contract) => (
                            <Combobox.Item
                                key={contract.typeOfRequest}
                                optionKey={contract.typeOfRequest}
                                optionValue={contract.typeOfRequest}
                            >
                                {contract.typeOfRequest}
                            </Combobox.Item>
                        ))}
                    </Combobox>
                </Layout.Item>
                <Layout.Item default={`1/3`}>
                    <p>{t('filters.date-of-receipt')}</p>
                    <Combobox onChange={(e: FilterType) => onDateChange(e)} defaultState={defaultFilter}>
                        <Combobox.Item optionKey={allOptionKey} optionValue={allOptionKey}>
                            {allOptionKey}
                        </Combobox.Item>
                        {getTimeFrameFilterItems(t).map((date) => (
                            <Combobox.Item key={date.key} optionKey={date.key} optionValue={date.value}>
                                {date.value}
                            </Combobox.Item>
                        ))}
                    </Combobox>
                </Layout.Item>
            </Layout>
        </ContentSection>
    );
};
