import { MyProfileLoadingPlaceholder, useAnalyticsPageViewTracker } from '@cp-shared-10/frontend-ui';
import { EditStatus } from 'common';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React, { useEffect } from 'react';
import { useCustomerType } from '../customer-type';
import { MyProfileUi } from './ui';
import { useMyProfile } from './useMyProfile';
import { useUpdateMyProfile } from './useUpdateMyProfile';

const MyProfileWithHandlers = withLoadingAndNoConnectionHandler(MyProfileUi);

export const MyProfile: React.FC = () => {
    const { data, isLoading, loadingError } = useMyProfile();
    const { updateMyProfile, myProfileData } = useUpdateMyProfile();
    const { data: customerType } = useCustomerType();

    useAnalyticsPageViewTracker('profile', !!data);

    const resetLastEditStatus = () => {
        updateMyProfile({
            ...myProfileData,
            lastEditStatus: {
                contactDetails: EditStatus.NOT_PERFORMED,
            },
        });
    };

    useEffect(() => {
        resetLastEditStatus();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <MyProfileWithHandlers
                isLoading={isLoading}
                myProfileData={data}
                hasError={!!loadingError}
                customerType={customerType}
                loadingPlaceholder={<MyProfileLoadingPlaceholder />}
            />
        </div>
    );
};
