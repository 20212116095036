import React from 'react';
import { DescriptionListAccordion, SectionHeading } from '@vwfs-bronson/bronson-react';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import { PartialEarlySettlementDetails } from 'common';
import { CPDate, formatCpDate } from '@cp-shared-10/common-utilities';

type CalculationDetailsProps = {
    partialEarlySettlementDetails: PartialEarlySettlementDetails;
    requestDate?: CPDate;
};

const resetTextTransformStyle = {
    textTransform: 'none' as const,
};

export const CalculationDetails: React.FC<CalculationDetailsProps> = ({
    partialEarlySettlementDetails,
    requestDate,
}) => {
    const { t, f } = useTranslationWithFormatting('partial-early-settlement');

    const capitalize = (str: string) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    return (
        <div>
            <SectionHeading level={3} subtitle={t('quote-details.calculation-details.text')}>
                {t('quote-details.calculation-details.headline')}
            </SectionHeading>

            <DescriptionListAccordion
                headerSplit
                totalLabel={t('quote-details.calculation-details.total-settlement-amount')}
                totalValue={f(partialEarlySettlementDetails.totalSettlementAmount, TranslationFormat.CURRENCY)}
            >
                <>
                    <DescriptionListAccordion.Item
                        nonCollapsable
                        icon="semantic-info"
                        tooltip={
                            <div
                                style={resetTextTransformStyle}
                                dangerouslySetInnerHTML={{
                                    __html: capitalize(
                                        t('quote-details.calculation-details.administration-fee.tooltip'),
                                    ),
                                }}
                            />
                        }
                        title={
                            <span style={resetTextTransformStyle}>
                                {capitalize(t('quote-details.calculation-details.administration-fee.text'))}
                            </span>
                        }
                        titleSplit={f(partialEarlySettlementDetails.administrationFee, TranslationFormat.CURRENCY)}
                    ></DescriptionListAccordion.Item>

                    <DescriptionListAccordion.Item
                        nonCollapsable
                        icon="semantic-info"
                        tooltip={
                            <span style={resetTextTransformStyle}>
                                {capitalize(t('quote-details.calculation-details.settlement-amount.tooltip'))}
                            </span>
                        }
                        title={
                            <span style={resetTextTransformStyle}>
                                {capitalize(t('quote-details.calculation-details.settlement-amount.text'))}
                            </span>
                        }
                        titleSplit={f(partialEarlySettlementDetails.calculateAmount, TranslationFormat.CURRENCY)}
                    ></DescriptionListAccordion.Item>
                    {typeof partialEarlySettlementDetails?.totalDueAmount === 'number' && (
                        <DescriptionListAccordion.Item
                            nonCollapsable
                            icon="semantic-info"
                            tooltip={
                                <span style={resetTextTransformStyle}>
                                    {capitalize(t('quote-details.calculation-details.total-due-amount.tooltip'))}
                                </span>
                            }
                            title={
                                <span style={resetTextTransformStyle}>
                                    {capitalize(t('quote-details.calculation-details.total-due-amount.text'))}
                                </span>
                            }
                            titleSplit={f(partialEarlySettlementDetails.totalDueAmount, TranslationFormat.CURRENCY)}
                        ></DescriptionListAccordion.Item>
                    )}
                    <DescriptionListAccordion.Item
                        nonCollapsable
                        title={
                            <span style={resetTextTransformStyle}>
                                {capitalize(t('quote-details.calculation-details.payment-date'))}
                            </span>
                        }
                        titleSplit={formatCpDate(requestDate).format('DD.MM.YYYY')}
                    ></DescriptionListAccordion.Item>
                </>
            </DescriptionListAccordion>
        </div>
    );
};
