import { useGetSimpleUpdateData } from '@cp-shared-10/frontend-integration';
import { updateMyProfile } from './MyProfileSlice';
import { MyProfileSelector } from './MyProfileSelector';
import { MyProfileData } from 'common';

type UpdateMyProfileType = {
    updateMyProfile: (data: MyProfileData) => void;
    myProfileData: MyProfileData;
};

export function useUpdateMyProfile(): UpdateMyProfileType {
    const {
        updateData,
        dataState: { data },
    } = useGetSimpleUpdateData(updateMyProfile, MyProfileSelector);
    return {
        updateMyProfile: updateData,
        myProfileData: data as MyProfileData,
    };
}
