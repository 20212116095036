import { CpDataApi } from 'cp-xhr';
import React from 'react';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-10/frontend-storybook-extensions';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-10/common-utilities';
import { getContractsEndpoint } from 'common';
import { ContractsInfoWithAllValues } from './ExampleData';

const mockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: ContractsInfoWithAllValues,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const RequestMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Contracts Response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions,
    url: getContractsEndpoint(),
};

export const RequestResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, RequestMock);

    return <div>{storyFn()}</div>;
};
