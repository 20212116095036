import { CpDataApi } from 'cp-xhr';
import React from 'react';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-10/frontend-storybook-extensions';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-10/common-utilities';
import {
    getChangeFiscalAddressEndpoint,
    getChangePostalAddressEndpoint,
    getCompanyIdentificationEndpoint,
    getMyProfileEndpoint,
    getPrivateIdentificationEndpoint,
} from 'common';
import { getStorageFileUploadEndpoint } from '@cp-shared-10/apis';

import {
    MyProfileBusinessWithAllValues,
    MyProfileBusinessWithMissingValues,
    MyProfilePrivateWithAllValues,
    MyProfilePrivateWithMissingValues,
    UploadFilesRequestResponse,
} from './examples/ExampleData';

const UploadFilesRequestMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Upload Files Request',
    method: 'post',
    defaultMockOption: 'Success',
    mockOptions: {
        Success: {
            status: 200,
            responseBody: UploadFilesRequestResponse,
        },
        'Business Error': DefaultBusinessMarketApiError,
    },
    url: getStorageFileUploadEndpoint(),
};

const UploadFileToTemporalUrlMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Upload File to temporal URL',
    method: 'put',
    defaultMockOption: 'Success',
    mockOptions: {
        Success: {
            status: 200,
            responseBody: {},
        },
        'Business Error': DefaultBusinessMarketApiError,
    },
    url: UploadFilesRequestResponse.temporalUrl,
};

const mockOptionsPrivate: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: MyProfilePrivateWithAllValues,
    },
    'With missing values': {
        status: 200,
        responseBody: MyProfilePrivateWithMissingValues,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const MyProfilePrivateMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: '',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions: mockOptionsPrivate,
    url: getMyProfileEndpoint(),
};

const ChangePrivateIdentificationRequestMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Change private identification request',
    method: 'put',
    defaultMockOption: 'Success',
    mockOptions: {
        Success: {
            status: 200,
            responseBody: {},
            delay: 3000,
        },
        'Business Error': DefaultBusinessMarketApiError,
    },
    url: getPrivateIdentificationEndpoint(),
};

const ChangePostalAddressRequestMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Change postal address request',
    method: 'put',
    defaultMockOption: 'Success',
    mockOptions: {
        Success: {
            status: 200,
            responseBody: {},
            delay: 3000,
        },
        'Business Error': { ...DefaultBusinessMarketApiError, delay: 3000 },
    },
    url: getChangePostalAddressEndpoint(),
};

const ChangeFiscalAddressRequestMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Change fiscal address request',
    method: 'put',
    defaultMockOption: 'Success',
    mockOptions: {
        Success: {
            status: 200,
            responseBody: {},
            delay: 3000,
        },
        'Business Error': { ...DefaultBusinessMarketApiError, delay: 3000 },
    },
    url: getChangeFiscalAddressEndpoint(),
};

export const MyProfileResponsePrivateMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [
        MyProfilePrivateMock,
        UploadFilesRequestMock,
        UploadFileToTemporalUrlMock,
        ChangePrivateIdentificationRequestMock,
        ChangePostalAddressRequestMock,
        ChangeFiscalAddressRequestMock,
    ]);

    return <div>{storyFn()}</div>;
};

const mockOptionsBusiness: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: MyProfileBusinessWithAllValues,
    },
    'With missing values': {
        status: 200,
        responseBody: MyProfileBusinessWithMissingValues,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const MyProfileBusinessMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'My Profile',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions: mockOptionsBusiness,
    url: getMyProfileEndpoint(),
};

const ChangeCompanyIdentificationRequestMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Change company identification request',
    method: 'put',
    defaultMockOption: 'Success',
    mockOptions: {
        Success: {
            status: 200,
            responseBody: {},
        },
        'Business Error': DefaultBusinessMarketApiError,
    },
    url: getCompanyIdentificationEndpoint(),
};

export const MyProfileResponseBusinessMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [
        MyProfileBusinessMock,
        UploadFilesRequestMock,
        UploadFileToTemporalUrlMock,
        ChangeCompanyIdentificationRequestMock,
        ChangePostalAddressRequestMock,
    ]);

    return <div>{storyFn()}</div>;
};
