import React from 'react';
import { useTranslation } from 'react-i18next';
import { VehicleDetailsUi } from './ui';
import { AccordionItem } from '@cp-shared-10/frontend-ui';
import { VehicleDetails as VehicleDetailsType } from 'common';
import { withLoadingAndNoConnectionHandler } from '../../../integration-wrapper';

type VehicleDetailsProps = {
    vehicleDetails?: VehicleDetailsType;
    isLoading: boolean;
    hasError: boolean;
    encryptedContractNumber: string;
};

const VehicleDetailsUiWithHandlers = withLoadingAndNoConnectionHandler(VehicleDetailsUi);

export const VehicleDetails: React.FC<VehicleDetailsProps> = ({
    vehicleDetails,
    isLoading,
    hasError,
    encryptedContractNumber,
}) => {
    const { t } = useTranslation('vehicle-details');

    const areAllFieldsEmpty =
        !vehicleDetails?.brandModelType &&
        !vehicleDetails?.licensePlate &&
        !vehicleDetails?.vin &&
        !vehicleDetails?.registrationDate;

    if (areAllFieldsEmpty && !hasError) {
        return null;
    }

    return (
        <AccordionItem title={t('headline')} contentClassName={'u-pr-xsmall'}>
            <VehicleDetailsUiWithHandlers
                vehicleDetails={vehicleDetails}
                encryptedContractNumber={encryptedContractNumber}
                isLoading={isLoading}
                hasError={hasError}
            />
        </AccordionItem>
    );
};
