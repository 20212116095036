import React from 'react';
import { useTranslation } from 'react-i18next';
import { AddressType, FiscalAddress, PostalAddress } from 'common';
import { DefinitionList, DefinitionListItem } from '@cp-shared-10/frontend-ui';

export const AddressList: React.FC<{ address?: PostalAddress | FiscalAddress; addressType: AddressType }> = ({
    address = {},
    addressType,
}) => {
    const { t } = useTranslation('my-profile-addresses-details');
    const translationPrefix = `consult-view.${addressType.toLocaleLowerCase()}`;

    const { street, postalCode, city, country } = address;
    const secondLine = addressType === AddressType.FISCAL ? (address as FiscalAddress).secondLine : undefined;

    const getStreet = (): string | React.ReactNode => {
        if (!secondLine && !street) return null;
        return addressType === AddressType.FISCAL ? (
            <>
                {secondLine}
                {secondLine ? <br /> : null}
                {street}
            </>
        ) : (
            street
        );
    };

    const addressList: DefinitionListItem[] = [
        {
            label: t(`${translationPrefix}.street`),
            value: getStreet() || '-',
            testId: 'address-details-street',
        },
        {
            label: t(`${translationPrefix}.postalCode`),
            value: postalCode || '-',
            testId: 'address-details-postal-code',
        },
        {
            label: t(`${translationPrefix}.city`),
            value: city || '-',
            testId: 'address-details-city',
        },
        {
            label: t(`${translationPrefix}.country`),
            value: country || '-',
            testId: 'address-details-country',
        },
    ];

    return <DefinitionList split={true} list={addressList} />;
};
