import { TFunction } from 'i18next';
import { EarlySettlementPaymentResult, PaymentDetailsPaymentResult } from 'common';
import { PaymentPageProps } from '../PaymentPage';
import { DefinitionListItem } from '@cp-shared-10/frontend-ui';
import { TranslationFormat } from '../../../localization/useTranslationWithFormatting';
import { isEqual } from 'lodash';

const emptyData: PaymentDetailsPaymentResult = { payBySquareImage: {} };

export const getEarlySettlementPaymentPageProps = (
    t: TFunction,
    f: Function,
    handleBackToPreviousView: () => void,
    data?: EarlySettlementPaymentResult & { isError?: boolean },
): PaymentPageProps => {
    if (!data || data.isError || isEqual(data, emptyData)) {
        return { manualBankTransferList: [], payBySquareList: [], isError: true, handleBackToPreviousView };
    }
    const headline = t('payment-page.headline');
    const description = t('payment-page.description');
    const noValuePlaceholder = '-';
    const { bankName, iban, referenceCode, payBySquareImage, paymentAmount, validityPeriodOfTheQuotation } = data;

    const payBySquareList: DefinitionListItem[] = [
        {
            label: t('payment-page.total-settlement-amount'),
            value: f(paymentAmount, TranslationFormat.CURRENCY) || noValuePlaceholder,
            testId: 'total-settlement-amount',
        },
        {
            label: t('payment-page.validity-period-of-quotation'),
            value: f(validityPeriodOfTheQuotation, TranslationFormat.DATE) || noValuePlaceholder,
            testId: 'validity-period-of-quotation',
        },
    ];

    const manualBankTransferList: DefinitionListItem[] = [
        {
            label: t('payment-page.bank-name'),
            value: bankName || noValuePlaceholder,
            testId: 'bank-name',
        },
        {
            label: t('payment-page.iban'),
            value: f(iban, TranslationFormat.IBAN) || noValuePlaceholder,
            testId: 'iban',
        },
        {
            label: t('payment-page.reference-code'),
            value: referenceCode || noValuePlaceholder,
            testId: 'reference-code',
        },
        ...payBySquareList,
    ];

    return {
        headline,
        description,
        manualBankTransferList,
        payBySquareList,
        handleBackToPreviousView,
        payBySquareImage,
    };
};
