import React from 'react';
import { useTranslation } from 'react-i18next';
import { DefinitionListItem } from '@cp-shared-10/frontend-ui';
import { ConfirmationView } from 'components/confirmation-view';
import { EditStatus } from 'common';

export type SummaryViewProps = {
    iban: string;
    goToPreviousStep: () => void;
    onSubmit: () => void;
    lastEditStatus: EditStatus;
};

export const SummaryView: React.FC<SummaryViewProps> = ({ goToPreviousStep, iban, onSubmit, lastEditStatus }) => {
    const { t } = useTranslation('overpayment-management');

    const items: DefinitionListItem[] = [
        {
            label: t('iban.label'),
            value: iban,
        },
    ];

    const isSuccess = lastEditStatus === EditStatus.SUCCESS;

    return (
        <ConfirmationView
            items={items}
            onConfirm={onSubmit}
            onCancel={goToPreviousStep}
            subHeadline={t('confirmation-text')}
            isSuccess={isSuccess}
        />
    );
};
