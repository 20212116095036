import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikProps, useFormikContext } from 'formik';
import { CleaveInput, UiBlockingSpinner } from '@cp-shared-10/frontend-ui';
import { IbanStateHandler, InitialValues } from './types';

export type ValidatedIbanInputProps = {
    isValidating: boolean;
    ibanStateHandler: IbanStateHandler;
    translationPrefix: string;
};

const cleaveOptionsIban = {
    delimiter: ' ',
    blocks: [2, 4, 4, 4, 4, 4, 4, 4, 4],
    numericOnly: false,
};

export const ValidatedIbanInput: React.FC<ValidatedIbanInputProps> = ({
    isValidating,
    ibanStateHandler,
    translationPrefix,
}) => {
    const { t } = useTranslation(translationPrefix);
    const { signedBankData } = ibanStateHandler;
    const { setFieldTouched }: FormikProps<InitialValues> = useFormikContext();
    const [shouldValidate, setShouldValidate] = useState<boolean>(true);

    useEffect(() => {
        if (ibanStateHandler.savedIban.iban && shouldValidate) {
            setFieldTouched('iban', true, shouldValidate);
            setShouldValidate(false);
        }
    }, [shouldValidate, setFieldTouched, ibanStateHandler.savedIban]);

    return (
        <div className={isValidating ? 'u-pt u-pb' : ''}>
            <UiBlockingSpinner isBlocking={isValidating}>
                <CleaveInput
                    cleaveOptions={cleaveOptionsIban}
                    label={t(`iban.label`)}
                    name="iban"
                    testId="iban-input"
                    stateIcon
                />
                {signedBankData?.data.isValid && (
                    <p className="u-text-success">{signedBankData.data.bankDetails?.bankName}</p>
                )}
            </UiBlockingSpinner>
        </div>
    );
};
