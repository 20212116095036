import React, { useEffect } from 'react';
import { TileSelectGroup, TileSelect, Layout, SectionHeading } from '@vwfs-bronson/bronson-react';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import { AcceptPartialEarlySettlementBody, ContractType, PartialEarlySettlementCalculated } from 'common';

export type SettlementOptionsProps = {
    partialEarlySettlementDetails?: Partial<PartialEarlySettlementCalculated>;
    setSelectedOption: Function;
    setAcceptRequest: Function;
    contractType?: ContractType;
    setIsOptionSelected: Function;
};

export const SettlementOptions: React.FC<SettlementOptionsProps> = ({
    partialEarlySettlementDetails,
    setSelectedOption,
    setAcceptRequest,
    contractType,
    setIsOptionSelected,
}) => {
    const { t, f } = useTranslationWithFormatting('partial-early-settlement');

    const pesReduceRateDetails = partialEarlySettlementDetails?.contractAfterReduceRate;

    const pesReduceEndDateDetails = partialEarlySettlementDetails?.contractAfterReduceEndDate;

    useEffect(() => {
        if (!pesReduceEndDateDetails) {
            setIsOptionSelected(true);
            setSelectedOption({ ...pesReduceRateDetails });
            setAcceptRequest((request: AcceptPartialEarlySettlementBody) => ({
                ...request,
                reduceType: 'R',
            }));
        } else if (!pesReduceRateDetails) {
            setIsOptionSelected(true);
            setSelectedOption({ ...pesReduceEndDateDetails });
            setAcceptRequest((request: AcceptPartialEarlySettlementBody) => ({
                ...request,
                reduceType: 'D',
            }));
        }
    }, [pesReduceRateDetails, pesReduceEndDateDetails, setAcceptRequest, setSelectedOption, setIsOptionSelected]);

    if (!partialEarlySettlementDetails) return null;

    return (
        <>
            <Layout.Item default="1/1" s="1/1" className="u-mt-large">
                <SectionHeading
                    center={true}
                    level={5}
                    subtitle={pesReduceRateDetails && pesReduceEndDateDetails && t('calculate.options.text')}
                >
                    {t('calculate.options.headline')}
                </SectionHeading>
            </Layout.Item>
            <Layout.Item className="u-text-center" default="1/1" s="1/1">
                <TileSelectGroup
                    layoutItemClassName={`${
                        pesReduceRateDetails && pesReduceEndDateDetails && 'u-1/2 u-1/2@l'
                    }    u-1/1@s`}
                >
                    {pesReduceRateDetails && (
                        <TileSelect
                            testId="optionSpi"
                            title={
                                contractType !== ContractType.BALLON_CREDIT &&
                                t('calculate.options.reduce-monthly-rate.headline')
                            }
                            inputType="radio"
                            value={'pesReduceRateDetails'}
                            radioGroupName="settlementOptions"
                            defaultChecked={!pesReduceEndDateDetails}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                setIsOptionSelected(event.target.checked);
                                setSelectedOption({ ...pesReduceRateDetails });
                                setAcceptRequest((request: AcceptPartialEarlySettlementBody) => ({
                                    ...request,
                                    reduceType: 'R',
                                }));
                            }}
                        >
                            <p>
                                <span className="u-block">
                                    {t('calculate.options.reduce-contract-end-date.monthly-rate')}
                                </span>
                                <span className="u-block">
                                    <strong className="u-text-success">
                                        {f(pesReduceRateDetails.newMonthlyRate, TranslationFormat.CURRENCY)}
                                    </strong>
                                </span>
                            </p>
                            <p>
                                <span className="u-block">
                                    {t('calculate.options.reduce-monthly-rate.contract-end-date')}
                                </span>
                                <span className="u-block">
                                    <strong>
                                        {f(pesReduceRateDetails.newContractEndDate, TranslationFormat.DATE)}
                                    </strong>
                                </span>
                            </p>
                            <p>
                                <span className="u-block">{t('calculate.options.remaining-installments')}</span>
                                <span className="u-block">
                                    <strong>
                                        {f(pesReduceRateDetails.newInstalmentsToSettle, TranslationFormat.NUMBER)}
                                    </strong>
                                </span>
                            </p>
                            <p>
                                <span className="u-block">{t('calculate.options.new-outstanding-capital')}</span>
                                <span className="u-block">
                                    <strong>
                                        {f(pesReduceRateDetails.newOutstandingCapital, TranslationFormat.CURRENCY)}
                                    </strong>
                                </span>
                            </p>
                        </TileSelect>
                    )}
                    {pesReduceEndDateDetails && (
                        <TileSelect
                            testId="optionSpp"
                            title={
                                contractType !== ContractType.BALLON_CREDIT &&
                                t('calculate.options.reduce-contract-end-date.headline')
                            }
                            inputType="radio"
                            value={'pesReduceEndDateDetails'}
                            radioGroupName="settlementOptions"
                            defaultChecked={!pesReduceRateDetails}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                setIsOptionSelected(event.target.checked);
                                setSelectedOption({ ...pesReduceEndDateDetails });
                                setAcceptRequest((request: AcceptPartialEarlySettlementBody) => ({
                                    ...request,
                                    reduceType: 'D',
                                }));
                            }}
                        >
                            <p>
                                <span className="u-block">
                                    {t('calculate.options.reduce-contract-end-date.monthly-rate')}
                                </span>
                                <span className="u-block">
                                    <strong className="u-text-success">
                                        {f(pesReduceEndDateDetails.newMonthlyRate, TranslationFormat.CURRENCY)}
                                    </strong>
                                </span>
                            </p>
                            <p>
                                <span className="u-block">
                                    {t('calculate.options.reduce-contract-end-date.new-contract-end-date')}
                                </span>
                                <span className="u-block">
                                    <strong className="u-text-success">
                                        {f(pesReduceEndDateDetails.newContractEndDate, TranslationFormat.DATE)}
                                    </strong>
                                </span>
                            </p>
                            <p>
                                <span className="u-block">{t('calculate.options.remaining-installments')}</span>
                                <span className="u-block">
                                    <strong className="u-text-success">
                                        {f(pesReduceEndDateDetails.newInstalmentsToSettle, TranslationFormat.NUMBER)}
                                    </strong>
                                </span>
                            </p>
                            <p>
                                <span className="u-block">{t('calculate.options.new-outstanding-capital')}</span>
                                <span className="u-block">
                                    <strong>
                                        {f(pesReduceEndDateDetails.newOutstandingCapital, TranslationFormat.CURRENCY)}
                                    </strong>
                                </span>
                            </p>
                        </TileSelect>
                    )}
                </TileSelectGroup>
            </Layout.Item>
        </>
    );
};
