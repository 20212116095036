import { ContentSection, Heading } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PaymentDetails } from '../../components/payment-details/PaymentDetails';

export const PaymentDetailsPage: React.FC = () => {
    const { t } = useTranslation('payment-details');
    const { encryptedContractNumber } = useParams<{ encryptedContractNumber: string }>();
    const title = t('title');
    return (
        <ContentSection pageWrapSize="medium">
            <Heading level={1}>{title}</Heading>
            <PaymentDetails encryptedContractNumber={encryptedContractNumber} />
        </ContentSection>
    );
};
