import React from 'react';
import { LicensePlate as LicensePlateShared } from '@vwfs-bronson/bronson-react';
import {
    AmortizationTableHeader as AmortizationTableHeaderShared,
    AmortizationTableTiles,
} from '@cp-shared-10/frontend-ui';
import { TranslationFormat, useTranslationWithFormatting } from '../../../localization/useTranslationWithFormatting';
import { AutoCreditFinancialDetails, ConsumerCreditFinancialDetails, FinancialLeasingFinancialDetails } from 'common';

export type AmortizationTableHeaderProps = {
    carInformation: string;
    contractCategory: string;
    contractNumber: string;
    countryCode: string;
    registrationNumber?: string;
    financialDetails: ConsumerCreditFinancialDetails | AutoCreditFinancialDetails | FinancialLeasingFinancialDetails;
};

export const AmortizationTableHeader: React.FC<AmortizationTableHeaderProps> = ({
    carInformation,
    contractCategory,
    contractNumber,
    countryCode,
    registrationNumber,
    financialDetails,
}) => {
    const { t, f } = useTranslationWithFormatting('amortization-details');

    const {
        totalInstalments,
        pendingInstalments,
        carPurchasePrice,
        firstPaymentDate,
        financeValueOfContract,
        lastPaymentDate,
    } = financialDetails;

    const tilesData = [
        {
            title: t('header.box-1.title'),
            icon: 'list-view',
            data: [
                {
                    label: t('header.box-1.field-1'),
                    value: totalInstalments || '-',
                },
                {
                    label: t('header.box-1.field-2'),
                    value: pendingInstalments || '-',
                },
            ],
        },
        {
            title: t('header.box-2.title'),
            icon: 'hand-circle-eurosign',
            data: [
                {
                    label: t('header.box-2.field-1'),
                    value: carPurchasePrice ? f(carPurchasePrice, TranslationFormat.CURRENCY) : '-',
                },
                {
                    label: t('header.box-2.field-2'),
                    value: financeValueOfContract ? f(financeValueOfContract, TranslationFormat.CURRENCY) : '-',
                },
            ],
        },
        {
            title: t('header.box-3.title'),
            icon: 'calendar',
            data: [
                {
                    label: t('header.box-3.field-1'),
                    value: firstPaymentDate ? f(firstPaymentDate, TranslationFormat.DATE) : '-',
                },
                {
                    label: t('header.box-3.field-2'),
                    value: lastPaymentDate ? f(lastPaymentDate, TranslationFormat.DATE) : '-',
                },
            ],
        },
    ];

    const Tiles = <AmortizationTableTiles tiles={tilesData} />;

    const LicensePlate = registrationNumber ? (
        <LicensePlateShared
            countryCode={countryCode}
            size={'small'}
            registrationNumber={registrationNumber}
            horizontalStripEu={true}
            euStars={true}
        />
    ) : null;

    return (
        <AmortizationTableHeaderShared
            carInformation={carInformation}
            contractCategory={contractCategory}
            contractNumber={contractNumber}
            licensePlateComponent={LicensePlate}
            tiles={Tiles}
        />
    );
};
