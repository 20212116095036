import { MarketingCardLoadingPlaceholder } from '@cp-shared-10/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React from 'react';
import { MarketingCardUi } from '../marketing-card-ui';
import { useDashboardMarketingCard } from './useDashboardMarketingCard';

const DashboardMarketingCardWithHandlers = withLoadingAndNoConnectionHandler(MarketingCardUi);

export const DashboardMarketingCard: React.FC = () => {
    const { cmsContent: dashboardMarketingCard, isLoading, loadingError } = useDashboardMarketingCard();

    return (
        <DashboardMarketingCardWithHandlers
            isLoading={isLoading}
            marketingCards={dashboardMarketingCard}
            hasError={!!loadingError}
            loadingPlaceholder={<MarketingCardLoadingPlaceholder />}
        />
    );
};
