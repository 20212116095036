import {
    Addresses,
    ContactDetails,
    FiscalAddress,
    Identification,
    MarketingSettingsWithCustomerType,
    MyProfileData,
    PostalAddress,
} from 'common';
import { formatCpDate } from '@cp-shared-10/common-utilities';
import { StorageUploadFileResponse } from '@cp-shared-10/apis';

export const IbanWithValue = 'SK3702000000005082849042';
export const IbanUndefined = undefined;

export const IdentificationPrivateWithAllValues: Identification = {
    customerType: 'Private',
    data: {
        title: 'Prof.',
        firstName: 'Oliver',
        surname: 'Prager',
        birthDate: formatCpDate('1990-01-06').toCpDate(),
        idCardNumber: 'HP577552',
    },
};

export const IdentificationPrivateWithMissingValues: Identification = {
    customerType: 'Private',
    data: {
        firstName: 'Oliver',
        surname: 'Prager',
        idCardNumber: 'HP577552',
    },
};

export const IdentificationPrivateWithNoValues: Identification = {
    customerType: 'Private',
    data: {},
};

export const IdentificationBusinessWithAllValues: Identification = {
    customerType: 'Business',
    data: {
        companyName: 'ABC Co.',
        companyNumber: 'AB123123',
        vatNumber: 'SK1234567890',
    },
};

export const IdentificationBusinessWithMissingValues: Identification = {
    customerType: 'Business',
    data: {
        companyName: 'ABC Co.',
    },
};

export const FiscalAddressWithAllValues: FiscalAddress = {
    street: 'Špitálska 2199/41',
    secondLine: 'some secondLine',
    postalCode: '81108',
    city: 'Bratislava',
    country: 'Slovenská republika',
};

export const PostalAddressWithAllValues: PostalAddress = {
    street: 'Špitálska 2199/41',
    postalCode: '81108',
    city: 'Bratislava',
    country: 'Slovenská republika',
};

export const AddressWithMissingValues = {
    street: 'Špitálska 2199/41',
    postalCode: '81108',
};

export const AddressWithoutStreetAndSecondLine = {
    postalCode: '81108',
    city: 'Bratislava',
    country: 'Slovenská republika',
};

export const AddressesWithAllValues: Addresses = {
    fiscalAddress: FiscalAddressWithAllValues,
    postalAddress: PostalAddressWithAllValues,
};

export const AddressesWithMissingValues: Addresses = {
    fiscalAddress: AddressWithMissingValues,
    postalAddress: AddressWithMissingValues,
};

export const AddressesWithNoValues: Addresses = {
    fiscalAddress: {},
    postalAddress: {},
};

export const ContactDetailsWithAllValues: ContactDetails = {
    email: 'oliver.prager@abc.io',
    mobilePhone: '0123123123',
    homePhone: '0903474706',
};

export const ContactDetailsWithAllValuesWithoutZeroOnTheBeginning: ContactDetails = {
    email: 'oliver.prager@abc.io',
    mobilePhone: '123123123',
    homePhone: '903474706',
};

export const ContactDetailsWithOnlyMobilePhone: ContactDetails = {
    mobilePhone: '0123123123',
};

export const MarketingSettingsWithCustomerTypeWithAllValues: MarketingSettingsWithCustomerType = {
    customerType: 'Private',
    email: false,
    phone: true,
    sms: false,
    post: true,
};

export const MarketingSettingsWithCustomerTypeBusiness: MarketingSettingsWithCustomerType = {
    customerType: 'Business',
    email: undefined,
    phone: undefined,
    sms: undefined,
    post: undefined,
};

export const MarketingSettingsWithCustomerTypeWithMissingValues: MarketingSettingsWithCustomerType = {
    customerType: 'Private',
    email: false,
    phone: undefined,
    sms: undefined,
    post: true,
};

export const ContactDetailsWithNoValues: ContactDetails = {};

export const MyProfilePrivateWithAllValues: MyProfileData = {
    addresses: AddressesWithAllValues,
    identification: IdentificationPrivateWithAllValues,
    contactDetails: ContactDetailsWithAllValues,
    marketingConsentSettings: MarketingSettingsWithCustomerTypeWithAllValues,
    iban: IbanWithValue,
};

export const MyProfilePrivateWithMissingValues: MyProfileData = {
    addresses: AddressesWithMissingValues,
    identification: IdentificationPrivateWithMissingValues,
    contactDetails: ContactDetailsWithOnlyMobilePhone,
    marketingConsentSettings: MarketingSettingsWithCustomerTypeWithMissingValues,
    iban: IbanWithValue,
};

export const MyProfilePrivateWithNoData: MyProfileData = {
    addresses: AddressesWithNoValues,
    identification: IdentificationPrivateWithNoValues,
    contactDetails: ContactDetailsWithNoValues,
    iban: undefined,
};

export const MyProfileBusinessWithAllValues: MyProfileData = {
    addresses: AddressesWithAllValues,
    identification: IdentificationBusinessWithAllValues,
    contactDetails: ContactDetailsWithAllValues,
    marketingConsentSettings: MarketingSettingsWithCustomerTypeBusiness,
    iban: IbanWithValue,
};

export const MyProfileBusinessWithMissingValues: MyProfileData = {
    addresses: AddressesWithMissingValues,
    identification: IdentificationBusinessWithMissingValues,
    contactDetails: ContactDetailsWithOnlyMobilePhone,
    iban: undefined,
};

export const UploadFilesRequestResponse: StorageUploadFileResponse = {
    id: '123456',
    name: 'file_name.png',
    temporalUrl: 'https://example.com/123456',
};
