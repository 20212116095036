import React, { useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BankData, SignedData } from '@cp-shared-10/apis';
import { UiBlockingSpinner } from '@cp-shared-10/frontend-ui';
import { EditStatus, getOverpaymentManagementEndpoint, ibanValidationUtils } from 'common';
import { CpDataApi } from 'cp-xhr';
import { dashboardPagePath } from 'components/navigation/paths';
import { getValidationSchema, IbanStateHandler, InitialValues } from '../validated-iban-input';
import { SummaryView } from './summary-view';
import { NotificationForLastEditStatus } from './NotificationForLastEditStatus';
import { IbanView } from './iban-view';

export type OverpaymentManagementProps = {
    encryptedContractNumber?: string;
};

type OverpaymentManagementView = 'IBAN_VIEW' | 'SUMMARY_VIEW';

const initialValues: InitialValues = { iban: '' };

export const OverpaymentManagement: React.FC<OverpaymentManagementProps> = ({ encryptedContractNumber }) => {
    const { t } = useTranslation('iban-validation');
    const history = useHistory();

    const [currentView, setCurrentView] = useState<OverpaymentManagementView>('IBAN_VIEW');
    const [lastEditStatus, setLastEditStatus] = useState<EditStatus>(EditStatus.NOT_PERFORMED);

    const [signedBankData, setSignedBankData] = useState<SignedData<BankData>>();
    const [savedIban, setSavedIban] = useState<{ iban?: string; error?: string }>({});
    const [isValidating, setIsValidating] = useState<boolean>(false);

    if (!encryptedContractNumber) return null;

    const ibanStateHandler: IbanStateHandler = {
        signedBankData,
        setSignedBankData,
        savedIban,
        setSavedIban,
    };
    const validationSchema = getValidationSchema(t, setIsValidating, ibanStateHandler);

    const onSubmit = (values: InitialValues, { setSubmitting }: FormikHelpers<InitialValues>) => {
        setSubmitting(true);
        CpDataApi.put(getOverpaymentManagementEndpoint(encryptedContractNumber), {
            iban: ibanValidationUtils.stripWhitespaces(values.iban),
        })
            .then(() => {
                setLastEditStatus(EditStatus.SUCCESS);
            })
            .catch(() => {
                setLastEditStatus(EditStatus.ERROR);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const redirectToDashboard = () => {
        history.push(dashboardPagePath());
    };

    return (
        <>
            <NotificationForLastEditStatus lastEditStatus={lastEditStatus} />
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                validateOnBlur={true}
            >
                {({ handleSubmit, values, validateForm, setFieldTouched, isSubmitting }) => (
                    <Form onSubmit={(e) => e.preventDefault()} data-testid="iban-form">
                        <UiBlockingSpinner isBlocking={isSubmitting}>
                            {currentView === 'IBAN_VIEW' && (
                                <IbanView
                                    isValidating={isValidating}
                                    ibanStateHandler={ibanStateHandler}
                                    onCancel={redirectToDashboard}
                                    goToNextStep={async () => {
                                        setFieldTouched('iban', true);
                                        const errors = await validateForm();
                                        if (!errors.iban) setCurrentView('SUMMARY_VIEW');
                                    }}
                                />
                            )}
                            {currentView === 'SUMMARY_VIEW' && (
                                <SummaryView
                                    goToPreviousStep={() => {
                                        setCurrentView('IBAN_VIEW');
                                        setLastEditStatus(EditStatus.NOT_PERFORMED);
                                    }}
                                    onSubmit={handleSubmit}
                                    iban={values.iban}
                                    lastEditStatus={lastEditStatus}
                                />
                            )}
                        </UiBlockingSpinner>
                    </Form>
                )}
            </Formik>
        </>
    );
};
