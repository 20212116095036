import React from 'react';
import { ContractDurationCalculationResponse, ContractDetails, ContractDurationChangeCombination } from 'common';
import {
    DefinitionList as DefinitionListShared,
    DefinitionListItem as DefinitionListItemShared,
} from '@cp-shared-10/frontend-ui';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import { Card, Layout, SectionHeading, DescriptionList, Combobox, Hr } from '@vwfs-bronson/bronson-react';
import { CurrentTerms } from '../ChangeContractDurationTypes';
import { Moment } from 'moment';
import { formatCpDate } from '@cp-shared-10/common-utilities';

export type ComparisonDurationSectionProps = {
    contractDetails: ContractDetails;
    allowedCombinations?: ContractDurationChangeCombination[];
    calculatedTerms?: ContractDurationCalculationResponse;
    setChosenOption: Function;
    chosenOption?: number;
    currentTerms?: CurrentTerms;
    setFieldValue: Function;
    validationErrors?: string;
};

export const ComparisonDurationSection: React.FC<ComparisonDurationSectionProps> = ({
    contractDetails,
    allowedCombinations,
    calculatedTerms,
    setChosenOption,
    currentTerms,
    setFieldValue,
    validationErrors,
    chosenOption,
}) => {
    const { t, f } = useTranslationWithFormatting('change-contract-duration');
    const durations = allowedCombinations
        ?.map((item) => item.newContractDurationField)
        .reduce((a, b) => a.concat(b), []);

    const getClassName = (newValue: Moment | number, oldValue: Moment | number): string => {
        return newValue < oldValue ? 'u-text-success' : '';
    };

    const newContractTermsList: DefinitionListItemShared[] = [
        {
            label: t('calculate.new-terms.monthly-rate'),
            value: calculatedTerms?.newMonthlyRate ? (
                <div
                    className={getClassName(
                        calculatedTerms?.newMonthlyRate ?? 0,
                        currentTerms?.monthlyRate ? currentTerms?.monthlyRate : 0,
                    )}
                >
                    {f(calculatedTerms?.newMonthlyRate, TranslationFormat.CURRENCY)}
                </div>
            ) : (
                '-'
            ),
        },
        {
            label: t('calculate.new-terms.last-installment'),
            value: calculatedTerms?.newInstalmentsToSettle ? (
                <div
                    className={getClassName(
                        calculatedTerms?.newInstalmentsToSettle,
                        contractDetails?.financial?.pendingInstalments ?? 0,
                    )}
                >
                    {f(calculatedTerms?.newInstalmentsToSettle, TranslationFormat.NUMBER)}
                </div>
            ) : (
                '-'
            ),
        },
        {
            label: t('calculate.new-terms.contract-end'),
            value: calculatedTerms?.newContractEndDate ? (
                <div
                    className={getClassName(
                        formatCpDate(calculatedTerms?.newContractEndDate).toMoment(),
                        formatCpDate(contractDetails?.financial?.contractEndDate).toMoment(),
                    )}
                >
                    {f(calculatedTerms?.newContractEndDate, TranslationFormat.DATE)}
                </div>
            ) : (
                '-'
            ),
        },
    ].filter((item) => !!item.value);

    const currentContractTermsList: DefinitionListItemShared[] = [
        {
            label: t('calculate.current-terms.current-lenght'),
            value: contractDetails.financial.term ? f(contractDetails.financial.term, TranslationFormat.NUMBER) : '-',
        },
        {
            label: t('calculate.current-terms.monthly-rate'),
            value: currentTerms?.monthlyRate ? f(currentTerms?.monthlyRate, TranslationFormat.CURRENCY) : '-',
        },
        {
            label: t('calculate.current-terms.last-installment'),
            value:
                'pendingInstalments' in contractDetails?.financial
                    ? f(contractDetails?.financial.pendingInstalments, TranslationFormat.NUMBER)
                    : '-',
        },
        {
            label: t('calculate.current-terms.contract-end'),
            value:
                'contractEndDate' in contractDetails?.financial
                    ? f(contractDetails?.financial?.contractEndDate, TranslationFormat.DATE)
                    : '-',
        },
    ].filter((item) => !!item.value);

    return (
        <Card element="article" className="u-text-left">
            <SectionHeading level={3} subtitle={t('calculate.text')}>
                {t('calculate.headline')}
            </SectionHeading>
            <Layout equalHeight>
                <Layout.Item default="5/12" s="1/1">
                    <div>
                        <h6>{t('calculate.new-terms.headline')}</h6>
                        <DescriptionList>
                            <DescriptionList.Detail title={t('calculate.new-terms.new-length')}>
                                <div style={{ width: '50%' }}>
                                    <Combobox
                                        testId={'mileageContractDurationDropdown'}
                                        name="newContractDuration"
                                        // error={!chosenOption || validationErrors}
                                        onChange={(duration: { key: string; value: string }[]) => {
                                            if (parseInt(duration[0].key) !== chosenOption) {
                                                setFieldValue('newContractDuration', duration[0].key);
                                                setChosenOption(parseInt(duration[0].key));
                                            }
                                        }}
                                        //optionKey={0}
                                    >
                                        {durations?.map((item, index) => (
                                            <Combobox.Item
                                                testId={`mileage-item-${index}`}
                                                optionValue={`${item.toString()} ${t('months')}`}
                                                optionKey={item.toString()}
                                                key={index}
                                            >
                                                <span>{`${item} ${t('months')}`}</span>
                                            </Combobox.Item>
                                        ))}
                                    </Combobox>
                                </div>
                                <span className="c-error-message" style={{ fontWeight: 400 }}>
                                    {validationErrors && validationErrors}
                                </span>
                            </DescriptionList.Detail>
                        </DescriptionList>
                        <DefinitionListShared list={newContractTermsList} />
                    </div>
                </Layout.Item>
                <Layout.Item default="1/12" s="1/1" className="u-border-r u-hide@s u-mh-xsmall" />
                <Hr className="u-hide@s-up u-mb-xsmall" />
                <Layout.Item default="5/12" s="1/1">
                    <div>
                        <h6>{t('calculate.current-terms.headline')}</h6>
                        <DefinitionListShared list={currentContractTermsList} />
                    </div>
                </Layout.Item>
            </Layout>
        </Card>
    );
};
