import React from 'react';
import { useTranslation } from 'react-i18next';
import { Notification, NotificationStatus } from '@cp-shared-10/frontend-ui';
import { EditStatus } from 'common';

export const NotificationForLastEditStatus: React.FC<{ lastEditStatus?: EditStatus }> = ({ lastEditStatus }) => {
    const { t } = useTranslation('proof-of-car-ownership');

    switch (lastEditStatus) {
        case EditStatus.SUCCESS: {
            return (
                <Notification
                    status={NotificationStatus.success}
                    headline={t('notification.success.title')}
                    text={t('notification.success.text')}
                    testId={'notification-success'}
                    className={'u-mb'}
                />
            );
        }
        case EditStatus.ERROR: {
            return (
                <Notification
                    status={NotificationStatus.error}
                    text={t('notification.error.text')}
                    testId={'notification-error'}
                    className={'u-mb'}
                />
            );
        }
        default: {
            return null;
        }
    }
};
