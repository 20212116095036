import { RegistrationLoadingPlaceholder, useAnalyticsPageViewTracker } from '@cp-shared-10/frontend-ui';
import React from 'react';
import { useUpdateCustomerType } from '../customer-type/useUpdateCustomerType';
import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';
import { RegistrationUi } from './ui';

const RegistrationWithHandlers = withLoadingAndNoConnectionHandler(RegistrationUi);

export const Registration: React.FC = () => {
    const { customerTypeData } = useUpdateCustomerType();
    useAnalyticsPageViewTracker('confirmIdentity', !customerTypeData);

    return (
        <RegistrationWithHandlers
            isLoading={false}
            hasError={!!false}
            loadingPlaceholder={<RegistrationLoadingPlaceholder />}
        />
    );
};
