import React from 'react';
import { HeroImage } from '@cp-shared-10/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { useGenericErrorPage } from './useGenericErrorPage';
import { GenericErrorType } from './utils/enums';
import { useLogin } from '../../../auth/useLogin';
import { useHistory } from 'react-router-dom';
import { dashboardPagePath, notAuthorizedPagePath } from '../../navigation/paths';

export type ErrorTypeProps = {
    errorType: GenericErrorType;
};

const HeroImageWithHandlers = withLoadingAndNoConnectionHandler(HeroImage);

export const GenericErrorPage: React.FC<ErrorTypeProps> = ({ errorType }) => {
    const { cmsContent: errorPageContents, isLoading, loadingError } = useGenericErrorPage();
    const history = useHistory();
    const login = useLogin();

    let errorPageContent = errorPageContents?.general;

    switch (errorType) {
        case GenericErrorType.CONSENT_DENIED_ERR:
            errorPageContent = errorPageContents?.consentDenied;
            break;
        case GenericErrorType.ALREADY_LOGIN_ERR:
            history.push(dashboardPagePath());
            break;
        case GenericErrorType.INVALID_SESSION_ERR:
            history.push(notAuthorizedPagePath());
            break;
    }

    return (
        <HeroImageWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            title={errorPageContent?.title || ''}
            subTitle={errorPageContent?.subTitle}
            buttonText={errorPageContent?.buttonText}
            clickHandler={login}
        />
    );
};
