import { CPDate } from '@cp-shared-10/common-utilities';
import { DynamicTableSortOptions, TableHeaderEntry } from '@cp-shared-10/frontend-ui';
import { PaymentOverviewDetails } from 'common';
import { TranslationFormat } from 'localization/useTranslationWithFormatting';

export type ToColumnValues = (string | { value: string; hiddenForMobile: boolean; nowrap?: boolean })[];

export const getTimestamp = (date: CPDate) => {
    const myDate = date.split('-');
    const newDate = new Date(parseInt(myDate[0]), parseInt(myDate[1]), parseInt(myDate[2]));
    return newDate.getTime();
};

export const generateRow = (row: PaymentOverviewDetails, f: Function): ToColumnValues => {
    return [
        f(row.transactionDate, TranslationFormat.DATE) || '',
        row.transactionName,
        { value: f(row.transactionSum, TranslationFormat.CURRENCY), nowrap: true },
        f(row.transactionDueDate, TranslationFormat.DATE) || '',
        { value: f(row.transactionIssueDate, TranslationFormat.DATE) || '', hiddenForMobile: true },
        { value: f(row.contractBalance, TranslationFormat.CURRENCY), nowrap: true },
        { value: row.transactionNote, hiddenForMobile: true },
    ];
};

export const getColumnHeadings = (t: Function): TableHeaderEntry[] => [
    t(`table.header.transaction-date`),
    t(`table.header.transaction-name`),
    t(`table.header.transaction-sum`),
    t(`table.header.transaction-due-date`),
    { heading: t(`table.header.transaction-issue-date`), hiddenForMobile: true },
    t(`table.header.contract-balance`),
    { heading: t(`table.header.transaction-note`), hiddenForMobile: true },
];

export const compareFunction = (row1: PaymentOverviewDetails, row2: PaymentOverviewDetails) =>
    row1.transactionDate && row2.transactionDate
        ? getTimestamp(row1.transactionDate) - getTimestamp(row2.transactionDate)
        : 0;

export const sortOptions: DynamicTableSortOptions<PaymentOverviewDetails> = {
    compare: compareFunction,
    reversed: true,
};
