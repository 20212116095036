import { CpDataApi } from 'cp-xhr';
import React from 'react';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-10/frontend-storybook-extensions';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-10/common-utilities';
import { getContractsEndpoint } from 'common';
import {
    ContractsNotAllowedForEarlySettlement,
    ContractsWithAllInvalidContracts,
    ContractsWithAllValidContracts,
    ContractsWithAllValues,
    ContractsWithMissingValues,
    NoContractsAvailable,
} from './ExampleData';
import { BundledProductsMock } from './bundled-products';
import { ContractDetailsMock } from './contract-details/ContractDetailsResponseMocks';
import {
    VehicleDetailsResponseMock,
    VehicleImageResponseMock,
} from './vehicle-details-and-image/VehicleDetailsAndImageResponseMocks';

const mockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: ContractsWithAllValues,
    },
    'Only Active Contracts': {
        status: 200,
        responseBody: ContractsWithAllValidContracts,
    },
    'Only Inactive Contracts': {
        status: 200,
        responseBody: ContractsWithAllInvalidContracts,
    },
    'No Contracts Available': {
        status: 200,
        responseBody: NoContractsAvailable,
    },
    'Contracts with missing values': {
        status: 200,
        responseBody: ContractsWithMissingValues,
    },
    'Contracts not allowed for Early Settlement': {
        status: 200,
        responseBody: ContractsNotAllowedForEarlySettlement,
    },
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const ContractsMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Contracts Response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions,
    url: getContractsEndpoint(),
};

export const ContractsResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [
        ContractsMock,
        ContractDetailsMock(ContractsWithAllValues[0]),
        BundledProductsMock,
        VehicleImageResponseMock,
        VehicleDetailsResponseMock,
    ]);

    return <div>{storyFn()}</div>;
};
