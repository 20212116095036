import { AuthenticationProvider, AutoLogout, ScrollToTopOnNavigation, Spinner } from '@cp-shared-10/frontend-ui';
import { AuthenticatedRoute, AuthorizedRoute } from 'auth';
import {
    amortizationTablePath,
    logoutPath,
    changeContactDetailsPath,
    changeFiscalAddressPath,
    changeLicensePlatePath,
    changeMarketingSettingsPath,
    changePostalAddressPath,
    changePaymentDayPath,
    changeContractDurationPath,
    cookiePolicyPath,
    dashboardPagePath,
    earlySettlementPath,
    faqPagePath,
    forbiddenPagePath,
    landingPagePath,
    legalNoticePath,
    manageInsurancePoliciesPath,
    myDocumentsPagePath,
    myProfilePagePath,
    myRequestsPagePath,
    notAuthorizedPagePath,
    notFoundPagePath,
    overpaymentManagementPath,
    partialEarlySettlementPath,
    changeMileagePath,
    paymentDetailsPath,
    paymentOverviewPath,
    privacyPolicyPath,
    proofOfCarOwnershipPath,
    errorPagePath,
    registrationPagePath,
    totalEarlySettlementPath,
    changeLogRequestPagePath,
} from 'components/navigation/paths';
import { ConnectedRouter } from 'connected-react-router';
import {
    AmortizationTablePage,
    LogoutSuccessPage,
    CookiePolicyPage,
    DashboardPage,
    EarlySettlementPage,
    FaqPage,
    ForbiddenPage,
    LandingPage,
    LegalNoticePage,
    ManageInsurancePoliciesPage,
    MasterPage,
    MyDocumentsPage,
    ChangeMileagePage,
    MyProfilePage,
    NotAuthorizedPage,
    NotFoundPage,
    OverpaymentManagementPage,
    PaymentDetailsPage,
    PrivacyPolicyPage,
    ProofOfCarOwnershipPage,
    RegistrationPage,
    RequestPage,
    PaymentOverviewPage,
    PartialEarlySettlementPage,
    TotalEarlySettlementPage,
    ChangeContractDurationPage,
    ChangeLogRequestPage,
    ErrorPage,
} from 'pages';
import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { AxiosConfiguration } from './auth/AxiosConfiguration';
import { history } from './store/rootReducer';

const App: React.FC = () => {
    return (
        <div className="App">
            <Suspense fallback={<Spinner center />}>
                <AuthenticationProvider loadingComponent={<Spinner center />}>
                    <AutoLogout
                        idleTimeInMinutes={30}
                        redirectUri={`${window.location.origin}${logoutPath()}?type=auto`}
                    >
                        <AxiosConfiguration>
                            <ConnectedRouter history={history}>
                                <LastLocationProvider>
                                    <ScrollToTopOnNavigation
                                        exceptions={[
                                            { from: /\S*/, to: changePostalAddressPath() },
                                            { from: /\S*/, to: changeFiscalAddressPath() },
                                            { from: /\S*/, to: changeContactDetailsPath() },
                                            { from: /\S*/, to: changeMarketingSettingsPath() },
                                            { from: /\S*/, to: changeLicensePlatePath('[\\w-]+') },
                                            { from: /\S*/, to: changePaymentDayPath('[\\w-]+') },
                                            { from: changeContactDetailsPath(), to: myProfilePagePath() },
                                            { from: changeFiscalAddressPath(), to: myProfilePagePath() },
                                            { from: changePostalAddressPath(), to: myProfilePagePath() },
                                            { from: changeMarketingSettingsPath(), to: myProfilePagePath() },
                                            { from: changeLicensePlatePath('[\\w-]+'), to: dashboardPagePath() },
                                            { from: changePaymentDayPath('[\\w-]+'), to: dashboardPagePath() },
                                        ]}
                                    />
                                    <MasterPage>
                                        <Switch>
                                            <Route path="*">
                                                <Switch>
                                                    <Route exact path={landingPagePath()}>
                                                        <LandingPage />
                                                    </Route>
                                                    <Route path={cookiePolicyPath()}>
                                                        <CookiePolicyPage />
                                                    </Route>
                                                    <Route path={legalNoticePath()}>
                                                        <LegalNoticePage />
                                                    </Route>
                                                    <Route path={privacyPolicyPath()}>
                                                        <PrivacyPolicyPage />
                                                    </Route>
                                                    <Route path={faqPagePath()}>
                                                        <FaqPage />
                                                    </Route>
                                                    <Route path={logoutPath()} component={LogoutSuccessPage} />
                                                    <AuthorizedRoute path={dashboardPagePath()}>
                                                        <DashboardPage />
                                                    </AuthorizedRoute>
                                                    <AuthorizedRoute path={myProfilePagePath()}>
                                                        <MyProfilePage />
                                                    </AuthorizedRoute>
                                                    <AuthorizedRoute path={changeLogRequestPagePath()}>
                                                        <ChangeLogRequestPage />
                                                    </AuthorizedRoute>
                                                    <AuthorizedRoute path={amortizationTablePath()}>
                                                        <AmortizationTablePage />
                                                    </AuthorizedRoute>
                                                    <AuthorizedRoute path={earlySettlementPath()}>
                                                        <EarlySettlementPage />
                                                    </AuthorizedRoute>
                                                    <AuthorizedRoute path={totalEarlySettlementPath()}>
                                                        <TotalEarlySettlementPage />
                                                    </AuthorizedRoute>
                                                    <AuthorizedRoute path={partialEarlySettlementPath()}>
                                                        <PartialEarlySettlementPage />
                                                    </AuthorizedRoute>
                                                    <AuthorizedRoute path={changeMileagePath()}>
                                                        <ChangeMileagePage />
                                                    </AuthorizedRoute>
                                                    <AuthorizedRoute path={proofOfCarOwnershipPath()}>
                                                        <ProofOfCarOwnershipPage />
                                                    </AuthorizedRoute>
                                                    <AuthorizedRoute path={myDocumentsPagePath()}>
                                                        <MyDocumentsPage />
                                                    </AuthorizedRoute>
                                                    <AuthorizedRoute path={changeContractDurationPath()}>
                                                        <ChangeContractDurationPage />
                                                    </AuthorizedRoute>
                                                    <AuthorizedRoute path={myRequestsPagePath()}>
                                                        <RequestPage />
                                                    </AuthorizedRoute>
                                                    <AuthorizedRoute path={overpaymentManagementPath()}>
                                                        <OverpaymentManagementPage />
                                                    </AuthorizedRoute>
                                                    <AuthorizedRoute path={manageInsurancePoliciesPath()}>
                                                        <ManageInsurancePoliciesPage />
                                                    </AuthorizedRoute>
                                                    <AuthorizedRoute path={paymentDetailsPath()}>
                                                        <PaymentDetailsPage />
                                                    </AuthorizedRoute>
                                                    <AuthenticatedRoute path={paymentOverviewPath()}>
                                                        <PaymentOverviewPage />
                                                    </AuthenticatedRoute>
                                                    <AuthenticatedRoute path={registrationPagePath()}>
                                                        <RegistrationPage />
                                                    </AuthenticatedRoute>
                                                    <Route path={forbiddenPagePath()}>
                                                        <ForbiddenPage />
                                                    </Route>
                                                    <Route path={errorPagePath()}>
                                                        <ErrorPage />
                                                    </Route>
                                                    <Route path={notAuthorizedPagePath()}>
                                                        <NotAuthorizedPage />
                                                    </Route>
                                                    <Route path={notFoundPagePath()}>
                                                        <NotFoundPage />
                                                    </Route>
                                                    <Route path="*">
                                                        <Redirect to={notFoundPagePath()} />
                                                    </Route>
                                                </Switch>
                                            </Route>
                                        </Switch>
                                    </MasterPage>
                                </LastLocationProvider>
                            </ConnectedRouter>
                        </AxiosConfiguration>
                    </AutoLogout>
                </AuthenticationProvider>
            </Suspense>
        </div>
    );
};

export default App;
