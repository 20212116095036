import { RequestLoadingPlaceholder, useAnalyticsPageViewTracker } from '@cp-shared-10/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React from 'react';
import { RequestUi } from './ui';
import { useRequest } from './useRequest';

const RequestWithHandlers = withLoadingAndNoConnectionHandler(RequestUi);

export const Request: React.FC = () => {
    const { data: contracts, isLoading: isContractsLoading, loadingError: contractsLoadingError } = useRequest();
    useAnalyticsPageViewTracker('requests', !!contracts);

    return (
        <RequestWithHandlers
            isLoading={isContractsLoading}
            contracts={contracts}
            hasError={!!contractsLoadingError}
            loadingPlaceholder={<RequestLoadingPlaceholder withTextArea numberOfButtons={1} />}
        />
    );
};
