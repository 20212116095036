import * as Yup from 'yup';
import { PartialEarlySettlementError } from '../types';
import { defaultPartialEarlySettlementError } from '../messages';
import { formatCpDate } from '@cp-shared-10/common-utilities';

export type partialEarlySettlementFormTypes = {
    settlementDate: string;
};

export const partialEarlySettlementValidationSchema = (
    errors: PartialEarlySettlementError = defaultPartialEarlySettlementError,
): Yup.SchemaOf<partialEarlySettlementFormTypes> =>
    Yup.object().shape({
        settlementDate: Yup.string()
            .required(errors.settlementDate.required)
            .test('valid', errors.settlementDate.valid, (value): boolean => {
                return (
                    formatCpDate(formatCpDate(value).format('YYYY-MM-DD')).isValid() ||
                    formatCpDate(formatCpDate(value).format('YYYY-MM-DD'), 'YYYY-MM-DD').isValid()
                );
            })
            .test('from-today', errors.settlementDate.min, (value): boolean =>
                formatCpDate(formatCpDate(value).format('YYYY-MM-DD'), 'YYYY-MM-DD')
                    .toMoment()
                    .startOf('day')
                    .isSameOrAfter(formatCpDate().toMoment()),
            ),
    });
