import React, { useState } from 'react';
import { useGetContractBasedApiData } from '@cp-shared-10/frontend-integration';
import { fetchPaymentDetails } from './PaymentDetailsSlice';
import { useContract } from '../contracts/useContract';
import { PaymentDetailsDataSelector } from './PaymentDetailsDataSelector';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import {
    Contract,
    GeneratePayment,
    getGeneratePaymentEndpoint,
    PaymentDetails as PaymentDetailsType,
    PaymentDetailsPaymentResult,
} from 'common';
import { PaymentDetailsOverview } from './payment-details-overview';
import { OnlinePaymentView } from './online-payment-view';
import { CpDataApi } from '../../cp-xhr';
import { useAnalyticsPageViewTracker } from '@cp-shared-10/frontend-ui';
import { PaymentDetailsContractHeader } from './header';

export type PaymentDetailsView = 'PAYMENT_DETAILS_OVERVIEW' | 'ONLINE_PAYMENT_VIEW';

export type PaymentDetailsProps = {
    encryptedContractNumber: string;
};

export type PaymentDetailsComponentProps = {
    contract?: Contract;
    paymentDetails?: PaymentDetailsType;
};

type GeneratePaymentHandle = {
    data?: PaymentDetailsPaymentResult;
    isSubmitting: boolean;
    isSubmitError: boolean;
};

export const PaymentDetailsComponent: React.FC<PaymentDetailsComponentProps> = ({ contract, paymentDetails }) => {
    const [currentView, setCurrentView] = useState<PaymentDetailsView>('PAYMENT_DETAILS_OVERVIEW');
    const [generatePayment, setGeneratePayment] = useState<GeneratePaymentHandle>({
        isSubmitError: false,
        isSubmitting: false,
    });

    if (!contract || !contract.contractNumber || !paymentDetails || !contract.encryptedContractNumber) return null;

    const { encryptedContractNumber } = contract;

    const handlePaymentAmountSelection = (paymentAmount: number): void => {
        setGeneratePayment((state) => ({ ...state, isSubmitError: false, isSubmitting: true }));
        const body: GeneratePayment = { paymentAmount };
        CpDataApi.post(getGeneratePaymentEndpoint(encryptedContractNumber), body)
            .then((result) => {
                const data = { ...result.data, totalDueAmount: paymentDetails.totalDueAmount };
                setGeneratePayment((state) => ({ ...state, data, isSubmitting: false }));
                setCurrentView('ONLINE_PAYMENT_VIEW');
            })
            .catch(() => {
                setGeneratePayment((state) => ({ ...state, isSubmitError: true, isSubmitting: false }));
            });
    };

    const handleBackToOverview = () => {
        setCurrentView('PAYMENT_DETAILS_OVERVIEW');
    };

    return (
        <>
            <PaymentDetailsContractHeader
                registrationNumber={contract.licensePlate || ''}
                carInformation={contract.brandModelType || ''}
                productName={contract.productName}
                contractNumber={contract.contractNumber}
                totalDueAmount={paymentDetails.totalDueAmount}
            />
            {currentView === 'PAYMENT_DETAILS_OVERVIEW' && (
                <PaymentDetailsOverview
                    paymentDetails={paymentDetails}
                    onSubmit={handlePaymentAmountSelection}
                    isSubmitting={generatePayment.isSubmitting}
                    isSubmitError={generatePayment.isSubmitError}
                />
            )}
            {currentView === 'ONLINE_PAYMENT_VIEW' &&
                generatePayment.data &&
                paymentDetails.detailsAvailable !== false && (
                    <OnlinePaymentView
                        generatePaymentResult={generatePayment.data}
                        handleBackToOverview={handleBackToOverview}
                    />
                )}
        </>
    );
};

const PaymentDetailsWithHandlers = withLoadingAndNoConnectionHandler(PaymentDetailsComponent);

export const PaymentDetails: React.FC<PaymentDetailsProps> = ({ encryptedContractNumber }) => {
    const {
        data: contract,
        isLoading: isLoadingContracts,
        loadingError: loadingErrorContract,
    } = useContract(encryptedContractNumber, { encryptedContractNumber: true });

    const {
        data: paymentDetails,
        isLoading: isLoadingPaymentDetails,
        loadingError: loadingErrorPaymentDetails,
    } = useGetContractBasedApiData(
        contract?.contractNumber || '',
        fetchPaymentDetails,
        PaymentDetailsDataSelector,
        contract?._links?.paymentDetails,
    );

    useAnalyticsPageViewTracker('unpaidDetails', !!contract && !!paymentDetails);

    const isLoading = isLoadingContracts || isLoadingPaymentDetails;
    const hasError = !!loadingErrorContract || !!loadingErrorPaymentDetails;

    return (
        <PaymentDetailsWithHandlers
            contract={contract}
            paymentDetails={paymentDetails}
            isLoading={isLoading}
            hasError={hasError}
        />
    );
};
