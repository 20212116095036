import React from 'react';
import { EditStatus, MarketingSettings, MarketingSettingsWithCustomerType } from 'common';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { ConsultView } from './consult-view/ConsultView';
import { EditView } from './edit-view/EditView';
import { useHistory } from 'react-router';
import { changeMarketingSettingsPath } from '../../navigation/paths';
import { useUpdateMyProfile } from '../useUpdateMyProfile';
import { useAnalyticsActionTracker, useAnalyticsFormTracker } from '@cp-shared-10/frontend-ui';

export type EditStatusWithData = {
    status: EditStatus;
    marketingConsentSettings?: MarketingSettings;
};

export const MarketingConsentSettingsSection: React.FC<{
    marketingConsentSettings?: MarketingSettingsWithCustomerType;
}> = ({ marketingConsentSettings }) => {
    const { path } = useRouteMatch();
    const history = useHistory();
    const { updateMyProfile, myProfileData } = useUpdateMyProfile();

    const { onError: trackOnError, onSuccess: trackOnSuccess } = useAnalyticsFormTracker({
        confirmError: 'onEditProfileConsentSettingsSubmitFailed',
        confirmSuccess: 'onEditProfileConsentSettingsSuccess',
    });
    const { onAction: onStart } = useAnalyticsActionTracker('onEditProfileConsentSettings');
    const { onAction: onCancel } = useAnalyticsActionTracker('onEditProfileCancel');

    const setLastEditStatus = (newEditStatus: EditStatus, updatedMarketingSettings?: MarketingSettings): void => {
        updateMyProfile({
            ...myProfileData,
            marketingConsentSettings: updatedMarketingSettings
                ? { ...updatedMarketingSettings, customerType: marketingConsentSettings?.customerType }
                : marketingConsentSettings,
            lastEditStatus: { ...myProfileData?.lastEditStatus, marketingConsentSettings: newEditStatus },
        });
    };

    const startEditing = () => {
        onStart();
        setLastEditStatus(EditStatus.NOT_PERFORMED);
        history.push(changeMarketingSettingsPath());
    };

    const cancelEditing = () => {
        onCancel('Consent settings');
        setLastEditStatus(EditStatus.NOT_PERFORMED);
        history.push(path);
    };

    const finishEditing = (newEditStatus: EditStatus, updatedMarketingSettings?: MarketingSettings): void => {
        newEditStatus === EditStatus.SUCCESS ? trackOnSuccess() : trackOnError();
        setLastEditStatus(newEditStatus, updatedMarketingSettings);
        history.push(path);
    };

    return (
        <Switch>
            <Route exact path={changeMarketingSettingsPath()}>
                <EditView
                    cancelEditing={cancelEditing}
                    finishEditing={finishEditing}
                    marketingConsentSettings={marketingConsentSettings}
                />
            </Route>
            <Route path={path}>
                <ConsultView
                    marketingConsentSettings={marketingConsentSettings}
                    startEditing={startEditing}
                    editStatus={myProfileData?.lastEditStatus?.marketingConsentSettings}
                />
            </Route>
        </Switch>
    );
};
