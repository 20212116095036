import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { getInitialValues, ProofOfCarOwnershipFormValues } from './initialValues';
import { CleaveInput, Spinner, ValidatedDate, ValidatedInput } from '@cp-shared-10/frontend-ui';
import { Button, ButtonContainer, Fieldset, Heading, Layout } from '@vwfs-bronson/bronson-react';
import { FileUpload } from '../file-upload';
import { dashboardPagePath } from '../navigation/paths';
import { SummaryView } from './summary-view';
import { getValidationSchema } from './validationSchema';
import { uploadFilesDirectToMediaStorage } from '@cp-shared-10/frontend-integration';
import { CpDataApi } from '../../cp-xhr';
import {
    DATE_FORMAT,
    EditStatus,
    FileInfo,
    getProofOfCarOwnershipEndpoint,
    ProofOfCarOwnership as ProofOfCarOwnershipResponse,
} from 'common';
import { NotificationForLastEditStatus } from './NotificationForLastEditStatus';
import { formatCpDate } from '@cp-shared-10/common-utilities';

const DOCTYPE_ID = 126;

export const ProofOfCarOwnership: React.FC<{ encryptedContractNumber?: string }> = ({ encryptedContractNumber }) => {
    const { t, i18n } = useTranslation('proof-of-car-ownership');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [lastEditStatus, setLastEditStatus] = useState<EditStatus>(EditStatus.NOT_PERFORMED);
    const history = useHistory();

    if (!encryptedContractNumber) return null;

    const language = i18n.languages[0];
    const onConfirm = (values: ProofOfCarOwnershipFormValues): void => {
        setIsSubmitting(true);

        const {
            licensePlate,
            dateOfFirstRegistrationInSk,
            yearOfProduction,
            numberOfTechnicalDocument,
            frontOfDocument,
            backOfDocument,
        } = values;
        const frontOfDocumentFile = uploadFilesDirectToMediaStorage(frontOfDocument, CpDataApi);
        const backOfDocumentFile = uploadFilesDirectToMediaStorage(backOfDocument, CpDataApi);
        const doAfterSuccessfulUpload = (frontOfDocumentId: string[], backOfDocumentId: string[]): void => {
            const body: ProofOfCarOwnershipResponse = {
                licensePlate,
                numberOfTechnicalDocument,
                dateOfFirstRegistrationInSk: formatCpDate(dateOfFirstRegistrationInSk, DATE_FORMAT).toCpDate(),
                yearOfProduction: Number(yearOfProduction),
                files: [
                    ...frontOfDocumentId.map(
                        (fileId: string): FileInfo => ({
                            docTypeId: DOCTYPE_ID,
                            fileId,
                        }),
                    ),
                    ...backOfDocumentId.map(
                        (fileId: string): FileInfo => ({
                            docTypeId: DOCTYPE_ID,
                            fileId,
                        }),
                    ),
                ],
            };
            CpDataApi.put(getProofOfCarOwnershipEndpoint(encryptedContractNumber), body)
                .then(() => {
                    setLastEditStatus(EditStatus.SUCCESS);
                })
                .catch(() => {
                    setLastEditStatus(EditStatus.ERROR);
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        };

        const doAfterUploadError = (): void => {
            setIsSubmitting(false);
            setLastEditStatus(EditStatus.ERROR);
        };

        Promise.all([frontOfDocumentFile, backOfDocumentFile])
            .then(([frontOfDocumentId, backOfDocumentId]) =>
                doAfterSuccessfulUpload(frontOfDocumentId, backOfDocumentId),
            )
            .catch(() => {
                doAfterUploadError();
            });
    };

    const onSubmit = (): void => {
        setShowConfirmation(true);
    };

    const onClose = (): void => {
        setShowConfirmation(false);
        setLastEditStatus(EditStatus.NOT_PERFORMED);
    };

    return (
        <>
            {isSubmitting && <Spinner fullPage={true} />}
            <Heading level={1}>{t('title')}</Heading>
            <NotificationForLastEditStatus lastEditStatus={lastEditStatus} />
            <Formik
                initialValues={getInitialValues()}
                onSubmit={onSubmit}
                validateOnBlur={true}
                validationSchema={getValidationSchema(t)}
            >
                {({ values, submitForm }): JSX.Element => (
                    <Form onSubmit={(e): void => e.preventDefault()} data-testid="proof-of-car-ownership-form">
                        {showConfirmation ? (
                            <SummaryView
                                formValues={values}
                                onConfirm={onConfirm}
                                onClose={onClose}
                                lastEditStatus={lastEditStatus}
                            />
                        ) : (
                            <Fieldset>
                                <Fieldset.Row>
                                    <div className={'u-mb'}>{t('subtitle')}</div>
                                    <div className={'u-mb'} dangerouslySetInnerHTML={{ __html: t('description') }} />
                                </Fieldset.Row>
                                <Fieldset.Row className={'u-mt'}>
                                    <Layout>
                                        <Layout.Item default="1/2" s="1/1">
                                            <ValidatedInput
                                                label={t('license-plate.label')}
                                                name={'licensePlate'}
                                                inputMode={'text'}
                                                testId="license-plate"
                                                isMandatory
                                            />
                                        </Layout.Item>
                                        <Layout.Item default="1/2" s="1/1">
                                            <ValidatedInput
                                                label={t('number-of-technical-document.label')}
                                                name={'numberOfTechnicalDocument'}
                                                inputMode={'text'}
                                                testId="number-of-technical-document"
                                                isMandatory
                                            />
                                        </Layout.Item>
                                    </Layout>
                                </Fieldset.Row>
                                <Fieldset.Row className={'u-mt u-mb'}>
                                    <Layout>
                                        <Layout.Item default="1/2" s="1/1">
                                            <CleaveInput
                                                cleaveOptions={{
                                                    delimiter: '',
                                                    blocks: [4],
                                                    numericOnly: true,
                                                }}
                                                inputMode={'numeric'}
                                                name={'yearOfProduction'}
                                                label={t('year-of-production.label')}
                                                isMandatory
                                                testId={'year-of-production'}
                                            />
                                        </Layout.Item>
                                        <Layout.Item default="1/2" s="1/1">
                                            <ValidatedDate
                                                name={'dateOfFirstRegistrationInSk'}
                                                label={t('date-of-first-registration-in-sk.label')}
                                                language={language}
                                                outputDateFormat={'d.m.Y'}
                                                isMandatory
                                                value={values.dateOfFirstRegistrationInSk}
                                                testId={'date-of-first-registration-in-sk'}
                                            />
                                        </Layout.Item>
                                    </Layout>
                                </Fieldset.Row>
                                <FileUpload name={'frontOfDocument'} label={t(t('front-of-document.label'))} />
                                <FileUpload
                                    name={'backOfDocument'}
                                    label={t('back-of-document.label')}
                                    isMandatory={false}
                                />
                                <Fieldset.Row>
                                    <ButtonContainer center>
                                        <Button
                                            secondary
                                            onClick={(): void => {
                                                history.push(dashboardPagePath());
                                            }}
                                            testId="cancel-button"
                                            type="button"
                                        >
                                            {t('translation:editable-section-nav.cancel')}
                                        </Button>
                                        <Button onClick={submitForm} testId="submit-button" type="submit">
                                            {t('translation:editable-section-nav.submit')}
                                        </Button>
                                    </ButtonContainer>
                                </Fieldset.Row>
                            </Fieldset>
                        )}
                    </Form>
                )}
            </Formik>
        </>
    );
};
