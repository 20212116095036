import { CPDate } from '@cp-shared-10/common-utilities';

export enum IdCardType {
    NATIONAL_ID = 'O',
    PASSPORT = 'C',
    RESIDENCE_CARD = 'P',
}

export type IdCard = {
    type: IdCardType;
    number: string;
    issueDate: CPDate;
    issueCity: string;
};
