import React from 'react';
import { useHistory } from 'react-router';
import { Button, ButtonContainer, Layout } from '@vwfs-bronson/bronson-react';
import { Notification, NotificationStatus } from '@cp-shared-10/frontend-ui';
import { EarlySettlement } from 'common';
import { dashboardPagePath } from 'components/navigation/paths';
import { useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';

type EarlySettlementExpiredProps = {
    earlySettlement: EarlySettlement;
};

export const EarlySettlementExpired: React.FC<EarlySettlementExpiredProps> = ({ earlySettlement }) => {
    const { t } = useTranslationWithFormatting('total-early-settlement');
    const history = useHistory();

    return (
        <Layout>
            <Layout.Item default="1/1">
                <Notification
                    status={NotificationStatus.info}
                    headline={earlySettlement.earlySettlementStatusInfo.statusHeadline}
                    testId={'expired-notification'}
                >
                    {earlySettlement.earlySettlementStatusInfo.statusDescription}
                </Notification>
            </Layout.Item>
            <Layout.Item default="1/1">
                <ButtonContainer center>
                    <Button
                        secondary
                        onClick={(): void => history.push(dashboardPagePath())}
                        testId="back-button"
                        type="button"
                    >
                        {t('allowed.buttons.back')}
                    </Button>
                </ButtonContainer>
            </Layout.Item>
        </Layout>
    );
};
