import React from 'react';
import { ContractType, FinancialDetails } from 'common';
import { DefinitionList, DefinitionListItem } from '@cp-shared-10/frontend-ui';
import { useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import { getMileageSectionListItems } from '../utils';
import { Button, Heading } from '@vwfs-bronson/bronson-react';
import { useHistory } from 'react-router-dom';
import { changeMileagePath } from 'components/navigation/paths';

type MileageSectionProps = {
    contractType: ContractType;
    encryptedContractNumber: string;
    changeMileageAvailable?: boolean;
    details: FinancialDetails;
};

export const MileageSection: React.FC<MileageSectionProps> = ({
    contractType,
    details,
    encryptedContractNumber,
    changeMileageAvailable,
}) => {
    const { t, f } = useTranslationWithFormatting('financial-details');
    const history = useHistory();

    if (!details) return null;

    const itemList: DefinitionListItem[] = getMileageSectionListItems(t, f, contractType, details);

    return (
        <>
            <Heading className={'c-data-overview__header u-pt'} level={6}>
                {t('mileage-section.headline')}
                {changeMileageAvailable && (
                    <Button
                        element="a"
                        link
                        small
                        className="c-data-overview__button u-mb-none u-mt-none u-mr-small"
                        icon="true"
                        iconReversed
                        onClick={() => history.push(changeMileagePath(encryptedContractNumber))}
                        testId={'edit-button'}
                    >
                        {t('translation:editable-section-nav.start')}
                    </Button>
                )}
            </Heading>
            <DefinitionList split={true} list={itemList} />
        </>
    );
};
