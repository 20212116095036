import React from 'react';
import { IconFooter as IconFooterShared, useAnalyticsActionTracker } from '@cp-shared-10/frontend-ui';
import { IconFooter } from 'common';
import { useLocation } from 'react-router-dom';

export const IconFooterUi: React.FC<{ iconFooter?: IconFooter }> = ({ iconFooter }) => {
    const { onAction } = useAnalyticsActionTracker('onClickEmailLink');
    const location = useLocation();
    const currentPageName = location.pathname.split('/')[1];
    if (!iconFooter) {
        return null;
    }

    return <IconFooterShared items={iconFooter.items} trackingFunction={onAction} currentPageName={currentPageName} />;
};
