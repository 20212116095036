import React from 'react';
import { ProductTable, LicensePlate, DescriptionList } from '@vwfs-bronson/bronson-react';
import { DividedContractHeader } from '@cp-shared-10/frontend-ui';

import { TranslationFormat, useTranslationWithFormatting } from '../../../localization/useTranslationWithFormatting';

export type PaymentDetailsHeaderProps = {
    carInformation: string;
    productName: string;
    contractNumber: string;
    registrationNumber?: string;
    totalDueAmount?: number;
};
export const PaymentDetailsContractHeader: React.FC<PaymentDetailsHeaderProps> = ({
    carInformation,
    productName,
    contractNumber,
    registrationNumber,
    totalDueAmount,
}) => {
    const { t, f } = useTranslationWithFormatting('payment-details');

    const totalDueAmountPart = totalDueAmount && (
        <ProductTable
            summary={
                <DescriptionList>
                    <DescriptionList.Group termText={t('total-due-amount')}>
                        <DescriptionList.Detail>{f(totalDueAmount, TranslationFormat.CURRENCY)}</DescriptionList.Detail>
                    </DescriptionList.Group>
                </DescriptionList>
            }
        />
    );

    const licensePlate = registrationNumber && (
        <LicensePlate
            countryCode={t('country-code')}
            size={'small'}
            registrationNumber={registrationNumber}
            horizontalStripEu={true}
            euStars={true}
        />
    );

    return (
        <DividedContractHeader
            carInformation={carInformation}
            contractNumber={contractNumber}
            contractCategory={productName}
            rightSideContent={totalDueAmountPart}
            contractIdentifier={licensePlate}
        />
    );
};
