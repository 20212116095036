import React from 'react';
import { Card } from '@vwfs-bronson/bronson-react';
import { CPDate } from '@cp-shared-10/common-utilities';
import { Contract, ContractType } from 'common';
import { UnpaidAlertNotification } from '../unpaid-alert-notification';
import { OverpaymentAlertNotification } from '../overpayment-alert-notification';
import { AutoCredit, BallonCredit, ConsumerCredit, FinancialLeasing, OperativeLeasing } from './summary';
import { Body } from './body';
import { ManageInsurancePoliciesAlertNotification } from '../manage-insurance-policies-alert-notification';
import { CarOwnershipAlertNotification } from '../car-ownership-alert-notification';

export const FinancialContract: React.FC<{ contract?: Contract; className?: string; defaultExpanded?: boolean }> = ({
    contract,
    className,
    defaultExpanded,
}) => {
    if (!contract || !contract.contractNumber || !contract.encryptedContractNumber) {
        return null;
    }
    const {
        isActive,
        contractNumber = '',
        nextPaymentAmount,
        nextPaymentDate,
        productName,
        status,
        startDate,
        endDate,
    } = contract;

    const { outstandingBalance } = contract as { outstandingBalance: number };
    const { lastPaymentDate } = contract as { lastPaymentDate: CPDate };
    const { contractType } = contract as { contractType: ContractType };

    const getContractSummaryComponent = (contractType?: ContractType) => {
        const commonProps = {
            isActive,
            nextPaymentDate,
            nextPaymentAmount,
            status,
            startDate,
            endDate,
        };

        switch (contractType) {
            case ContractType.CONSUMER_CREDIT:
                return <ConsumerCredit outstandingBalance={outstandingBalance} {...commonProps} />;
            case ContractType.AUTO_CREDIT:
                return <AutoCredit outstandingBalance={outstandingBalance} {...commonProps} />;
            case ContractType.FINANCIAL_LEASING:
                return <FinancialLeasing lastPaymentDate={lastPaymentDate} {...commonProps} />;
            case ContractType.OPERATIVE_LEASING:
                return <OperativeLeasing lastPaymentDate={lastPaymentDate} {...commonProps} />;
            case ContractType.BALLON_CREDIT:
                return <BallonCredit outstandingBalance={outstandingBalance} {...commonProps} />;
        }
    };

    return (
        <Card
            element="article"
            expandable
            title={`${productName} ${contractNumber}`}
            className={className}
            defaultExpanded={defaultExpanded}
            contentLarge={<Body contract={contract} />}
        >
            {getContractSummaryComponent(contractType)}
            <UnpaidAlertNotification contract={contract} />
            <OverpaymentAlertNotification contract={contract} />
            <ManageInsurancePoliciesAlertNotification
                encryptedContractNumber={contract.encryptedContractNumber}
                hpAlert={contract.hpAlert}
            />
            <CarOwnershipAlertNotification
                encryptedContractNumber={contract.encryptedContractNumber}
                carOwnershipAlert={contract.carOwnershipAlert}
            />
        </Card>
    );
};
