import * as Yup from 'yup';
// eslint-disable-next-line no-duplicate-imports
import { StringSchema } from 'yup';
import { RequiredStringSchema } from 'yup/lib/string';
import { ChangeCompanyIdentificationRequest } from '../../apis';
import { defaultCompanyIdentificationError, defaultCompanyNameError, defaultVatNumberError } from '../messages';
import { CompanyIdentificationError, CompanyNameError, VatNumberError } from '../types';
import { filesInfoValidator } from './FileInfoValidator';

export const companyNameValidator = (
    errors: CompanyNameError = defaultCompanyNameError,
): RequiredStringSchema<string | undefined> => Yup.string().trim().required(errors.required).max(200, errors.max);

export const vatNumberValidator = (errors: VatNumberError = defaultVatNumberError): StringSchema<string | undefined> =>
    Yup.string()
        .trim()
        .max(12, errors.max)
        .matches(new RegExp(/^$|^SK(\d{10})$/), errors.matches);

export const companyIdentificationValidator = (
    errors: CompanyIdentificationError = defaultCompanyIdentificationError,
): Yup.SchemaOf<ChangeCompanyIdentificationRequest> =>
    filesInfoValidator(errors.files, { min: 0, max: 4 }).shape({
        companyName: companyNameValidator(errors.companyName),
        vatNumber: vatNumberValidator(errors.vatNumber),
    });
