import { WithDefaultCpIntegrationErrors } from '@cp-shared-10/common-utilities';
import {
    Notification,
    NotificationStatus,
    ScrollToTopOnNavigation,
    Spinner,
    useAnalyticsFormTracker,
} from '@cp-shared-10/frontend-ui';
import { RegistrationError, RegistrationResult } from 'common';
import { Layout, Tabs } from '@vwfs-bronson/bronson-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NoConnectionNotification } from '../../../notifications/no-connection/NoConnectionNotification';
import { tabs } from './availableTabs';
import { BusinessIdentificationForm } from './business-identification-form';
import { PersonIdentificationForm } from './person-identification-form';
import { BusinessFormProps, CustomerType, PersonFormProps } from './types';

type IdentificationProps = {
    onSubmitSuccess: (
        result: RegistrationResult,
        customerType: CustomerType,
        registrationForm: PersonFormProps | BusinessFormProps,
    ) => void;
};

type RegistrationRequestProps = {
    isLoading: boolean;
    errorCode?: WithDefaultCpIntegrationErrors<RegistrationError>;
    errorMessage?: string;
    lastFourDigitsOfPhoneNumber?: string;
    customerId?: string;
};

export const Identification: React.FC<IdentificationProps> = ({ onSubmitSuccess }) => {
    const { t } = useTranslation('registration');
    const [registrationRequest, setRegistrationRequest] = useState<RegistrationRequestProps>({
        isLoading: false,
        errorCode: undefined,
        errorMessage: '',
        lastFourDigitsOfPhoneNumber: '',
        customerId: '',
    });

    const { onError: trackOnError, onSuccess: trackOnSuccess } = useAnalyticsFormTracker({
        confirmError: 'onConfirmIdentiyAuthFailed',
        confirmSuccess: 'onConfirmIdentiyAuthSuccess',
    });

    const onSubmitClick = (): void => {
        setRegistrationRequest({ isLoading: true });
    };

    const onSuccess = (customerType: CustomerType) => (
        result: RegistrationResult,
        registrationForm: BusinessFormProps | PersonFormProps,
    ): void => {
        const { customerId, phoneNumberEnding: lastFourDigitsOfPhoneNumber } = result;
        setRegistrationRequest({
            isLoading: false,
            errorCode: undefined,
            errorMessage: '',
            lastFourDigitsOfPhoneNumber,
            customerId,
        });
        trackOnSuccess();
        onSubmitSuccess(result, customerType, registrationForm);
    };

    const onError = (errorCode: WithDefaultCpIntegrationErrors<RegistrationError>, errorMessage: string): void => {
        setRegistrationRequest({
            isLoading: false,
            errorCode,
            errorMessage,
            lastFourDigitsOfPhoneNumber: '',
            customerId: '',
        });
        trackOnError();
    };

    const getErrorNotificationLabel = (
        errorCode: WithDefaultCpIntegrationErrors<RegistrationError>,
        errorMessage = '',
    ): string => {
        switch (errorCode) {
            case RegistrationError.CUSTOMER_NUMBER_NOT_FOUND:
            case RegistrationError.NOT_FOUND_BY_NAME:
            case RegistrationError.NOT_FOUND_BY_COMPANY_NAME:
            case RegistrationError.ALREADY_REGISTERED:
            case RegistrationError.PHONE_NUMBER_NOT_FOUND:
            case RegistrationError.INCORRECT_CUSTOMER_TYPE:
            case RegistrationError.IS_LOCKED_OUT:
                return errorMessage;
        }
        return '';
    };

    const isRegistrationErrorCode = (errorCode: WithDefaultCpIntegrationErrors<RegistrationError>): boolean => {
        return errorCode in RegistrationError;
    };

    const { isLoading, errorCode, errorMessage } = registrationRequest;

    return (
        <>
            {isLoading && <Spinner fullPage={true} />}
            <Layout.Item default={'1/1'} className={'u-mb u-text-center'}>
                <h1>{t('registration-form.head.title')}</h1>
                <p>{t('registration-form.head.info-identification')}</p>
                <p>{t('registration-form.head.info-legal-identification')}</p>
                {errorCode && !isRegistrationErrorCode(errorCode) && (
                    <ScrollToTopOnNavigation>
                        <NoConnectionNotification />
                    </ScrollToTopOnNavigation>
                )}
                {errorCode && isRegistrationErrorCode(errorCode) && (
                    <ScrollToTopOnNavigation>
                        <Notification
                            testId={'error-notification-identification-form'}
                            status={NotificationStatus.error}
                            text={getErrorNotificationLabel(errorCode, errorMessage)}
                        />
                    </ScrollToTopOnNavigation>
                )}
            </Layout.Item>
            <Layout.Item default={'1/1'}>
                <Tabs defaultSelected={tabs.private} testId={'registrationTabs'}>
                    <Tabs.Header>
                        <Tabs.HeaderItem tabId={tabs.private} className={`cp-tab-button-${tabs.private}`}>
                            {t('registration-form.head.tabs.person.label')}
                        </Tabs.HeaderItem>
                        <Tabs.HeaderItem tabId={tabs.business} className={`cp-tab-button-${tabs.business}`}>
                            {t('registration-form.head.tabs.business.label')}
                        </Tabs.HeaderItem>
                    </Tabs.Header>
                    <Tabs.Content>
                        <Tabs.ContentItem tabId={tabs.private} testId={`tab-content-${tabs.private}`}>
                            <PersonIdentificationForm
                                onSubmitClick={onSubmitClick}
                                onSuccess={onSuccess(CustomerType.PRIVATE)}
                                onError={onError}
                            />
                        </Tabs.ContentItem>
                        <Tabs.ContentItem tabId={tabs.business} testId={`tab-content-${tabs.business}`}>
                            <BusinessIdentificationForm
                                onSubmitClick={onSubmitClick}
                                onSuccess={onSuccess(CustomerType.BUSINESS)}
                                onError={onError}
                            />
                        </Tabs.ContentItem>
                    </Tabs.Content>
                </Tabs>
            </Layout.Item>
        </>
    );
};
