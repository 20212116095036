import React from 'react';
import { isEmpty } from 'lodash';
import { OtherInsurance as OtherInsuranceType } from 'common';
import { DefinitionList, DefinitionListItem } from '@cp-shared-10/frontend-ui';
import { useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';

export const OtherInsurance: React.FC<{ otherInsurance?: OtherInsuranceType; isOperativeLeasing: boolean }> = ({
    otherInsurance,
    isOperativeLeasing,
}) => {
    const { t } = useTranslationWithFormatting('bundled-products');

    if (!otherInsurance || isEmpty(otherInsurance)) {
        return null;
    }

    const { productName, insurer, status, monthlyAmount } = otherInsurance;

    const itemList: DefinitionListItem[] = [
        {
            label: t('other-insurance.insurer'),
            value: insurer || '-',
            testId: 'other-insurance-insurer',
        },
        {
            label: t('other-insurance.status'),
            value: status || '-',
            testId: 'other-insurance-status',
        },
        {
            label: t('other-insurance.monthly-amount'),
            value: !isOperativeLeasing ? monthlyAmount || '-' : undefined,
            testId: 'other-insurance-monthly-amount',
        },
    ].filter(({ value }) => value !== undefined);

    return (
        <div className="u-mt">
            <h5>{productName}</h5>
            <DefinitionList split={true} list={itemList} />
        </div>
    );
};
