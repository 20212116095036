import { ContentEndpoint } from 'common';
import {
    AbstractContentState,
    CmsContentSlice,
    createCmsContentSlice as createCmsContentSliceShared,
} from '@cp-shared-10/frontend-integration';
import { CpContentApi } from 'cp-xhr';
import { currentBrand } from 'config';

export function createCmsContentSlice<TBusinessObject>({
    contentName,
    contentEndpoint,
    initialContent,
}: {
    contentName: string;
    contentEndpoint: ContentEndpoint;
    initialContent?: TBusinessObject;
}): CmsContentSlice<AbstractContentState<TBusinessObject>> {
    return createCmsContentSliceShared<TBusinessObject>({
        contentCallback(locale: string) {
            return CpContentApi.get<TBusinessObject>(contentEndpoint(locale, currentBrand)).then(
                (response) => response.data,
            );
        },
        contentName,
        initialContent,
    });
}
