import React from 'react';
import { useTranslation } from 'react-i18next';
import { AccordionItem } from '@cp-shared-10/frontend-ui';
import { ContractPartiesUi } from './ui';
import { ContractParties as ContractPartiesType } from 'common';
import { withLoadingAndNoConnectionHandler } from '../../../integration-wrapper';

type ContractPartiesProps = { contractPartiesDetails?: ContractPartiesType; isLoading: boolean; hasError: boolean };

const ContractPartiesUiWithHandlers = withLoadingAndNoConnectionHandler(ContractPartiesUi);

export const ContractParties: React.FC<ContractPartiesProps> = ({ contractPartiesDetails, isLoading, hasError }) => {
    const { t } = useTranslation('contract-parties');

    const areAllFieldsEmpty =
        !contractPartiesDetails?.firstHolder &&
        !contractPartiesDetails?.secondHolder1 &&
        !contractPartiesDetails?.secondHolder2 &&
        !contractPartiesDetails?.guarantor1 &&
        !contractPartiesDetails?.guarantor2;

    if (areAllFieldsEmpty && !hasError) {
        return null;
    }

    return (
        <AccordionItem title={t('headline')} contentClassName={'u-pr-none'}>
            <div>
                <ContractPartiesUiWithHandlers
                    contractParties={contractPartiesDetails}
                    isLoading={isLoading}
                    hasError={hasError}
                />
            </div>
        </AccordionItem>
    );
};
