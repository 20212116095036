import { Contract } from 'common';
import { formatCpDate } from '@cp-shared-10/common-utilities';

export type GroupedContractsByVin = Array<Contract[]>;

export const groupContractsByVin = (contracts: Contract[]): GroupedContractsByVin => {
    return contracts.reduce((acc: GroupedContractsByVin, current) => {
        const groupIndex = acc.findIndex((group) => group.some((contract) => contract.vin === current.vin));
        if (groupIndex > -1) {
            acc[groupIndex].push(current);
        } else {
            acc.push([current]);
        }
        return acc;
    }, []);
};

export const sortGroupedContractsByStartDate = (groupedContracts?: Array<Contract[]>): Array<Contract[]> => {
    if (!groupedContracts?.length) {
        return [];
    }
    return groupedContracts.map((contractGroup) =>
        contractGroup.sort((a, b) => {
            return formatCpDate(a.startDate).toMoment().isAfter(b.startDate) ? -1 : 1;
        }),
    );
};
