import React from 'react';
import { TranslationFormat, useTranslationWithFormatting } from '../../../../localization/useTranslationWithFormatting';
import { DefinitionListHorizontal, DefinitionListItem, DividedContractHeader } from '@cp-shared-10/frontend-ui';
import { ProductTable, LicensePlate } from '@vwfs-bronson/bronson-react';
import { EarlySettlementSummary } from 'common';

export type EarlySettlementHeaderProps = {
    carInformation: string;
    productName: string;
    contractNumber: string;
    registrationNumber?: string;
    summary?: EarlySettlementSummary;
};

export const EarlySettlementHeader: React.FC<EarlySettlementHeaderProps> = ({
    carInformation,
    productName,
    contractNumber,
    registrationNumber,
    summary,
}) => {
    const { t, f } = useTranslationWithFormatting('total-early-settlement');

    const licensePlate = registrationNumber && (
        <LicensePlate
            countryCode={t('header.country-code')}
            size={'small'}
            registrationNumber={registrationNumber}
            horizontalStripEu={true}
            euStars={true}
        />
    );

    const getListItems = (summary: EarlySettlementSummary): DefinitionListItem[] => {
        const { nextDueAmount, pendingInstalments, nextDueDate, expectedContractEndDate, pendingCapital } = summary;
        return [
            {
                label: t('header.monthly-rate'),
                value: nextDueAmount ? f(nextDueAmount, TranslationFormat.CURRENCY) : '-',
                testId: 'next-due-amount',
            },
            {
                label: t('header.remaining-instalments'),
                value: pendingInstalments ? f(pendingInstalments, TranslationFormat.NUMBER) : '-',
                testId: 'pending-instalments',
            },
            {
                label: t('header.next-due-date'),
                value: nextDueDate ? f(nextDueDate, TranslationFormat.DATE) : '-',
                testId: 'next-due-date',
            },
            {
                label: t('header.end-of-contract'),
                value: expectedContractEndDate ? f(expectedContractEndDate, TranslationFormat.DATE) : '-',
                testId: 'expected-contract-end-date',
            },

            {
                label: t('header.outstanding-capital'),
                value: pendingCapital ? f(pendingCapital, TranslationFormat.CURRENCY) : '-',
                testId: 'pending-capital',
            },
        ];
    };

    const rightSideContent = summary && (
        <ProductTable>
            <ProductTable.Section>
                <DefinitionListHorizontal list={getListItems(summary)} />
            </ProductTable.Section>
        </ProductTable>
    );

    return (
        <DividedContractHeader
            carInformation={carInformation}
            contractNumber={contractNumber}
            contractCategory={productName}
            rightSideContent={rightSideContent}
            contractIdentifier={licensePlate}
        />
    );
};
