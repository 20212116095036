import React from 'react';
import {
    ContractIdentifierFilterItem,
    DocumentTypeFilterItem,
    PostboxTableLocalization,
    TimeFrameFilterItem,
} from '@cp-shared-10/frontend-ui';
import { formatCpDate } from '@cp-shared-10/common-utilities';
import { MyDocument } from 'common';
import { TFunction } from 'i18next';
import { Moment } from 'moment';

type Timeframe = 'LAST_3_MONTHS' | 'LAST_6_MONTHS' | 'LAST_12_MONTHS' | 'LAST_2_YEARS' | 'LAST_4_YEARS';

export const timeframeMomentMapping: Record<Timeframe, Moment> = {
    LAST_3_MONTHS: formatCpDate().subtract(3, 'months').toMoment(),
    LAST_6_MONTHS: formatCpDate().subtract(6, 'months').toMoment(),
    LAST_12_MONTHS: formatCpDate().subtract(12, 'months').toMoment(),
    LAST_2_YEARS: formatCpDate().subtract(2, 'years').toMoment(),
    LAST_4_YEARS: formatCpDate().subtract(4, 'years').toMoment(),
};

export const sortDocuments = (myDocumentsData: MyDocument[]): MyDocument[] => {
    return [...myDocumentsData].sort((a, b) => formatCpDate(b.uploadDate).unix() - formatCpDate(a.uploadDate).unix());
};

export const getContractFilterItems = (
    documents: MyDocument[],
    contractItemGetter: (document: MyDocument) => React.ReactNode,
): ContractIdentifierFilterItem[] => {
    return documents.reduce(
        (acc: ContractIdentifierFilterItem[], document: MyDocument): ContractIdentifierFilterItem[] => {
            if (document.contractNumber && !acc.some((filterItem) => filterItem.value === document.contractNumber)) {
                acc.push({
                    contractIdentifier: document.contractNumber || '',
                    value: document.contractNumber || '',
                    reactNode: contractItemGetter(document),
                });
            }
            return acc;
        },
        [],
    );
};

export const getDocumentTypeFilterItems = (documents: MyDocument[]): DocumentTypeFilterItem[] => {
    return documents.reduce((acc: DocumentTypeFilterItem[], document: MyDocument): DocumentTypeFilterItem[] => {
        if (!acc.some((filterItem) => filterItem.value === document.categoryType)) {
            acc.push({
                documentType: document.categoryType,
                value: document.categoryType,
            });
        }
        return acc;
    }, []);
};

export const getTimeFrameFilterItems = (t: TFunction): TimeFrameFilterItem[] => {
    const translationPrefix = 'filters.time-frame';
    const currentDate = formatCpDate().toMoment();
    return [
        {
            from: timeframeMomentMapping['LAST_3_MONTHS'],
            to: currentDate,
            value: t(`${translationPrefix}.last-3-months`),
            key: '3m',
        },
        {
            from: timeframeMomentMapping['LAST_6_MONTHS'],
            to: currentDate,
            value: t(`${translationPrefix}.last-6-months`),
            key: '6m',
        },
        {
            from: timeframeMomentMapping['LAST_12_MONTHS'],
            to: currentDate,
            value: t(`${translationPrefix}.last-12-months`),
            key: '12m',
        },
        {
            from: timeframeMomentMapping['LAST_2_YEARS'],
            to: currentDate,
            value: t(`${translationPrefix}.last-2-years`),
            key: '2y',
        },
        {
            from: timeframeMomentMapping['LAST_4_YEARS'],
            to: currentDate,
            value: t(`${translationPrefix}.last-4-years`),
            key: '4y',
        },
    ];
};

export const getTableLocalization = (t: TFunction): PostboxTableLocalization => {
    return {
        'month.01.name': t('translation:months.jan.long'),
        'month.01.name.short': t('translation:months.jan.short'),
        'month.02.name': t('translation:months.feb.long'),
        'month.02.name.short': t('translation:months.feb.short'),
        'month.03.name': t('translation:months.mar.long'),
        'month.03.name.short': t('translation:months.mar.short'),
        'month.04.name': t('translation:months.apr.long'),
        'month.04.name.short': t('translation:months.apr.short'),
        'month.05.name': t('translation:months.may.long'),
        'month.05.name.short': t('translation:months.may.short'),
        'month.06.name': t('translation:months.jun.long'),
        'month.06.name.short': t('translation:months.jun.short'),
        'month.07.name': t('translation:months.jul.long'),
        'month.07.name.short': t('translation:months.jul.short'),
        'month.08.name': t('translation:months.aug.long'),
        'month.08.name.short': t('translation:months.aug.short'),
        'month.09.name': t('translation:months.sep.long'),
        'month.09.name.short': t('translation:months.sep.short'),
        'month.10.name': t('translation:months.oct.long'),
        'month.10.name.short': t('translation:months.oct.short'),
        'month.11.name': t('translation:months.nov.long'),
        'month.11.name.short': t('translation:months.nov.short'),
        'month.12.name': t('translation:months.dec.long'),
        'month.12.name.short': t('translation:months.dec.short'),
    };
};
