import { Button, ButtonContainer, Layout } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { Notification, NotificationStatus } from '@cp-shared-10/frontend-ui';
import { useHistory } from 'react-router';
import { dashboardPagePath } from 'components/navigation/paths';
import { useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';

export const EarlySettlementCalculating: React.FC = () => {
    const { t } = useTranslationWithFormatting('total-early-settlement');
    const history = useHistory();

    return (
        <Layout>
            <Layout.Item default="1/1">
                <Notification
                    status={NotificationStatus.info}
                    headline={t('calculating.headline')}
                    testId={'calculating-notification'}
                >
                    {t('calculating.description')}
                </Notification>
            </Layout.Item>
            <Layout.Item default="1/1">
                <ButtonContainer center>
                    <Button
                        secondary
                        onClick={(): void => history.push(dashboardPagePath())}
                        testId="cancel-button"
                        type="button"
                    >
                        {t('buttons.back')}
                    </Button>
                </ButtonContainer>
            </Layout.Item>
        </Layout>
    );
};
