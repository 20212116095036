import { ContractSummaryItemProps } from '@cp-shared-10/frontend-ui';
import { ContractStatus, formatAsDate } from 'common';
import { CPDate } from '@cp-shared-10/common-utilities';
import { TFunction } from 'i18next';

export const getInactiveContractSummary = (
    t: TFunction,
    status?: ContractStatus,
    startDate?: CPDate,
    endDate?: CPDate,
): Array<ContractSummaryItemProps> => {
    const startDateValue = startDate ? formatAsDate(startDate) : '-';
    const endDateValue = endDate ? formatAsDate(endDate) : '-';
    const statusValue = status ? t(`contract-statuses.${status}`) : '-';
    return [
        { value: statusValue, label: t('contract-status'), testClass: 'contract-status' },
        {
            value: startDateValue,
            label: t('start-date'),
            testClass: 'start-date',
        },
        {
            value: endDateValue,
            label: t('end-date'),
            testClass: 'end-date',
        },
    ];
};
